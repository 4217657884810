import { TrashCan, Upload } from '@carbon/icons-react';
import styled from '@emotion/styled';
import {
  Spinner,
  VariantButton,
  VariantIconButton
} from '@tablecheck/tablekit-react';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Avatar } from 'components/TopNav/Avatar';
import {
  removeUserAvatar,
  uploadImage
} from 'components/TopNav/UserPanel/uploadImage';
import { useImagePreview } from 'components/TopNav/UserPanel/useImagePreview';
import { useAppContext } from 'scenes/Routes/AppContext';
import { MerchantUser } from 'types/MerchantUser';

const AvatarWrapper = styled.div`
  display: grid;
  grid-template: '. avatar button' 1fr / 1fr 128px 1fr;
  gap: var(--spacing-l2);
  align-items: center;
  justify-items: center;
`;

const ImageWrapper = styled.div`
  aspect-ratio: 1;
  justify-self: center;
  grid-area: avatar;
`;

const NoImageButton = styled(VariantButton.Ghost)`
  aspect-ratio: 1;
  grid-area: avatar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  white-space: normal;
`;

const UploadIconPadding = styled.span`
  padding: var(--spacing-l2);
`;

const RemoveButton = styled(VariantIconButton.Danger)`
  grid-area: button;
`;

export function UserAvatar({
  avatarFile,
  setAvatarFile,
  user
}: {
  avatarFile?: File;
  setAvatarFile: (file: File | undefined) => void;
  user: MerchantUser;
}): JSX.Element {
  const [t] = useTranslation();
  const {
    actions: { updateUser }
  } = useAppContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const imagePreview = useImagePreview({
    imageFile: avatarFile,
    imageUrls: user.avatar_urls,
    size: 'sm'
  });
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.png', '.jpeg'] },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      setIsLoading(true);
      await uploadImage(acceptedFiles[0]).then((updatedUser) => {
        updateUser(updatedUser);
      });
      setAvatarFile(acceptedFiles[0]);
      setIsLoading(false);
    }
  });

  return (
    <AvatarWrapper {...getRootProps()}>
      <input {...getInputProps()} />
      {imagePreview ? (
        <ImageWrapper>
          <Avatar
            name={user.name}
            initials={user.initials}
            imageUrl={imagePreview}
            size="large"
          />
        </ImageWrapper>
      ) : (
        <NoImageButton>
          <UploadIconPadding>
            {isLoading ? <Spinner aria-busy /> : <Upload size={16} />}
          </UploadIconPadding>
          <span>{t('portal:user_panel.upload_new_image')}</span>
        </NoImageButton>
      )}
      {imagePreview ? (
        <RemoveButton
          disabled={isLoading || !imagePreview}
          aria-busy={isLoading}
          onClick={async (event) => {
            setIsLoading(true);
            event.preventDefault();
            event.stopPropagation();
            await removeUserAvatar().then((updatedUser) => {
              updateUser(updatedUser);
            });
            setAvatarFile(undefined);
            setIsLoading(false);
          }}
          aria-label={t('portal:user_panel.remove_image')}
        >
          <TrashCan size={16} />
        </RemoveButton>
      ) : null}
    </AvatarWrapper>
  );
}
