import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
import singleSpaReact from 'single-spa-react';

import { portalClassicTheme, portalDarkTheme } from 'App/themes';
import { portalThemeNamespace } from 'App/types';
import { SspaAppProps } from 'apps/types';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { i18n } from 'i18n';
import { AppCard } from 'scenes/LandingPage/AppCard';
import { AppNotices } from 'scenes/LandingPage/AppNotices';
import { MerchantUser } from 'types/MerchantUser';
import { useAppTheme } from 'utils/appTheme/exports';
import { AppName, SSPA_DIV_ID } from 'utils/constants';

import { AppsGrid, MainHeading, Wrapper } from './styled';

const classicTheme = { [portalThemeNamespace]: portalClassicTheme };
const darkTheme = { [portalThemeNamespace]: portalDarkTheme };

function MainWrapper({ user }: { user: MerchantUser }) {
  const [t] = useTranslation();
  const appTheme = useAppTheme();
  const theme = appTheme === 'dark' ? darkTheme : classicTheme;

  return (
    <EmotionThemeProvider theme={theme}>
      <Wrapper>
        <MainHeading>
          {t('portal:messages.welcome', { userName: user.name })}
        </MainHeading>
        <AppsGrid>
          <AppCard appName={AppName.Manager} />
          <AppCard appName={AppName.Insight} />
          <AppCard appName={AppName.Settings} />
        </AppsGrid>
        <AppNotices />
      </Wrapper>
    </EmotionThemeProvider>
  );
}

const rootComponent = ({ currentUser }: SspaAppProps) => (
  <ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <MainWrapper user={currentUser.current_user} />
    </I18nextProvider>
  </ErrorBoundary>
);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent,
  domElementGetter: () => document.getElementById(SSPA_DIV_ID) as HTMLElement
});

export const bootstrap = [reactLifecycles.bootstrap];

export const mount = [reactLifecycles.mount];

export const unmount = [reactLifecycles.unmount];
