export const SSPA_DIV_ID = 'sspa-content';

export enum AppName {
  Manager = 'manager',
  Insight = 'insight',
  Settings = 'settings',
  LegacySettings = 'legacy-settings',
  Home = 'home'
}

export const CATEGORY_ID = {
  [AppName.Insight]: '900000150726',
  [AppName.Home]: '',
  [AppName.Settings]: '900000076526',
  [AppName.Manager]: '900000076926'
};

export const AVAILABLE_CATEGORY_IDS = [
  CATEGORY_ID[AppName.Insight],
  CATEGORY_ID[AppName.Settings],
  CATEGORY_ID[AppName.Manager]
];

export enum RoutePath {
  Help = 'help',
  Settings = 'settings'
}
