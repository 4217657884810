import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { Size } from '@tablecheck/tablekit-theme';
import { useTranslation } from 'react-i18next';

import {
  ScrollableContent,
  MessageSentContainer,
  MessageSentHeader,
  MessageSentDescription,
  MessageSentIcon
} from 'components/TopNav/HelpCenter/styled';

export function MessageSent(): JSX.Element {
  const [t] = useTranslation();

  return (
    <ScrollableContent>
      <MessageSentContainer>
        <MessageSentIcon icon={faCheckCircle} size={Size.Large} />
        <MessageSentHeader>
          {t('portal:help.message_sent_header')}
        </MessageSentHeader>
        <MessageSentDescription>
          {t('portal:help.message_sent_description')}
        </MessageSentDescription>
      </MessageSentContainer>
    </ScrollableContent>
  );
}
