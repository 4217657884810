import queryString from 'querystring';

import { Item } from '@tablecheck/tablekit-item';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MikanWrapper } from 'components/MikanWrapper';
import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { EmptyList } from 'components/TopNav/HelpCenter/elements/EmptyList';
import { Loader } from 'components/TopNav/HelpCenter/elements/Loader';
import { SearchTextHighlighter } from 'components/TopNav/HelpCenter/elements/SearchTextHighlighter';
import {
  SubTitle,
  ScrollableContent
} from 'components/TopNav/HelpCenter/styled';

export function SearchResults(): JSX.Element {
  const [t] = useTranslation();
  const history = useHistory();
  const {
    articles,
    categoryId,
    sections,
    isLoadingArticles,
    search,
    isGlobalSearch,
    actions: { searchArticles }
  } = React.useContext(HelpCenterContext);
  React.useEffect(() => {
    if (!search) return () => {};
    return searchArticles(search, categoryId);
  }, [searchArticles, search, categoryId]);

  return (
    <ScrollableContent>
      {isLoadingArticles ? (
        <Loader />
      ) : (
        <>
          <SubTitle>{t('portal:help.help_center_suggestions')}</SubTitle>
          {articles.length ? (
            articles.map((article, index) => {
              const section = sections.find(
                ({ id }) => id === article.section_id
              );
              return (
                <Item
                  key={article.id}
                  onClick={() => {
                    const queryParams: Record<
                      string,
                      string | React.ReactText
                    > = {
                      view: 'help',
                      sectionId: article.section_id,
                      articleId: article.id
                    };
                    const currentCategoryId =
                      categoryId || section?.category_id;
                    if (currentCategoryId) {
                      queryParams.categoryId = currentCategoryId;
                    }
                    if (search) {
                      queryParams.search = search;
                    }
                    if (isGlobalSearch) {
                      queryParams.isGlobalSearch = 'true';
                    }
                    history.replace(`?${queryString.stringify(queryParams)}`);
                  }}
                >
                  {`${index + 1}. `}
                  <SearchTextHighlighter text={article.name}>
                    {(text) => <MikanWrapper text={text} />}
                  </SearchTextHighlighter>
                </Item>
              );
            })
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </ScrollableContent>
  );
}
