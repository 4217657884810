import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { loadAppNotices } from 'components/AppNoticesModal/loadAppNotices';
import { AppNoticeAPI } from 'components/AppNoticesModal/types';
import { getNonBillingAppNotices } from 'components/AppNoticesModal/utils';
import { AppName } from 'utils/constants';
import { reportSentryError } from 'utils/sentry';

export const usePortalAppNotices = (): AppNoticeAPI[] => {
  const [, { language }] = useTranslation();
  const [appNotices, setAppNotices] = React.useState<AppNoticeAPI[]>([]);

  React.useEffect(() => {
    loadAppNotices(AppName.Home, language)
      .then((notices) => {
        setAppNotices(getNonBillingAppNotices(notices));
      })
      .catch((error) => {
        console.error(error);
        reportSentryError(error, {
          extra: {
            info: 'Portal app notices load failed'
          }
        });
      });
  }, [language]);

  return appNotices;
};
