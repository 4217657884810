import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { loadAppNotices } from 'components/AppNoticesModal/loadAppNotices';
import { AppNoticeAPI } from 'components/AppNoticesModal/types';
import {
  getBillingAppNotices,
  getFilteredBillingAppNoticesToShow,
  getFilteredNonBillingAppNoticesToShow,
  getSeenAppNotices,
  setSeenAppNotices
} from 'components/AppNoticesModal/utils';
import { MerchantUser } from 'types/MerchantUser';
import { AppName } from 'utils/constants';
import { reportSentryError } from 'utils/sentry';

type AppNoticesState = {
  userId: MerchantUser['id'];
  allAppNotices: AppNoticeAPI[];
  appNoticesToShow: AppNoticeAPI[];
  hasNewNotices: boolean;
  hasAgreementAccepted: boolean;
  mode: 'billing' | 'login' | null;
};

type AppNoticesActions = {
  onOpen: () => void;
  onClose: () => void;
  toggleAgreement: () => void;
};

type SetAppNoticesAction = {
  payload: { appNotices: AppNoticeAPI[]; appName: AppName };
  type: 'SET_APP_NOTICES';
};

type OpenAppNoticesModalAction = {
  payload: { appNotices: AppNoticeAPI[] };
  type: 'OPEN_MODAL_DIALOG';
};

type CloseAppNoticesModalAction = {
  payload: { appNotices: AppNoticeAPI[]; appName: AppName };
  type: 'CLOSE_MODAL_DIALOG';
};

type ToggleAgreementAction = {
  type: 'TOGGLE_AGREEMENT';
};

type ShowNonBillingAppNoticesAction = {
  payload: { appName: AppName };
  type: 'SHOW_NON_BILLING_APP_NOTICES';
};

type AppNoticesAction =
  | SetAppNoticesAction
  | OpenAppNoticesModalAction
  | CloseAppNoticesModalAction
  | ToggleAgreementAction
  | ShowNonBillingAppNoticesAction;

const appNoticesReducer = (
  state: AppNoticesState,
  action: AppNoticesAction
): AppNoticesState => {
  switch (action.type) {
    case 'SET_APP_NOTICES': {
      const { userId } = state;
      const {
        payload: { appNotices, appName }
      } = action;
      let allAppNotices = appNotices;
      if (appName === AppName.Home) {
        allAppNotices = getBillingAppNotices(allAppNotices);
      }
      const seenAppNotices = getSeenAppNotices(userId, appName);
      const nonBillingAppNoticesToShow = getFilteredNonBillingAppNoticesToShow(
        userId,
        appName,
        allAppNotices
      );
      const billingAppNoticesToShow = getFilteredBillingAppNoticesToShow(
        userId,
        appName,
        allAppNotices
      );
      const hasBillingAppNoticesToShow = !!billingAppNoticesToShow.length;
      const hasNewNotices = allAppNotices.some(
        ({ id }) => !seenAppNotices.includes(id)
      );

      return {
        ...state,
        allAppNotices,
        mode: hasBillingAppNoticesToShow ? 'billing' : 'login',
        appNoticesToShow: hasBillingAppNoticesToShow
          ? billingAppNoticesToShow
          : nonBillingAppNoticesToShow,
        hasAgreementAccepted: !hasBillingAppNoticesToShow,
        hasNewNotices
      };
    }
    case 'OPEN_MODAL_DIALOG': {
      return {
        ...state,
        appNoticesToShow: action.payload.appNotices,
        mode: null
      };
    }
    case 'CLOSE_MODAL_DIALOG': {
      const { allAppNotices, userId, appNoticesToShow } = state;
      const {
        payload: { appNotices, appName }
      } = action;
      const newSeenAppNotices = setSeenAppNotices(
        userId,
        appName,
        allAppNotices.map(({ id }) => id),
        appNoticesToShow.map(({ id }) => id)
      );
      const hasNewNotices = allAppNotices.some(
        ({ id }) => !newSeenAppNotices.includes(id)
      );

      return {
        ...state,
        appNoticesToShow: appNotices,
        hasNewNotices,
        mode: null
      };
    }
    case 'TOGGLE_AGREEMENT': {
      return {
        ...state,
        hasAgreementAccepted: !state.hasAgreementAccepted
      };
    }
    case 'SHOW_NON_BILLING_APP_NOTICES': {
      const { userId, allAppNotices } = state;
      const {
        payload: { appName }
      } = action;
      const appNoticesToShow = getFilteredNonBillingAppNoticesToShow(
        userId,
        appName,
        allAppNotices
      );

      return {
        ...state,
        appNoticesToShow,
        mode: 'login'
      };
    }
    default: {
      return state;
    }
  }
};

const getDefaultAppNoticesState = (
  userId: MerchantUser['id']
): AppNoticesState => ({
  allAppNotices: [],
  appNoticesToShow: [],
  userId,
  hasNewNotices: false,
  hasAgreementAccepted: false,
  mode: null
});

export const useAppNotices = (
  appName: AppName,
  userId: MerchantUser['id']
): {
  state: AppNoticesState;
  actions: AppNoticesActions;
} => {
  const [, { language }] = useTranslation();
  const [state, dispatch] = React.useReducer(appNoticesReducer, undefined, () =>
    getDefaultAppNoticesState(userId)
  );

  React.useEffect(() => {
    if (appName) {
      loadAppNotices(appName, language)
        .then((appNotices) => {
          dispatch({
            type: 'SET_APP_NOTICES',
            payload: { appNotices, appName }
          });
        })
        .catch((error) => {
          console.error(error);
          reportSentryError(error, {
            extra: {
              info: 'App notices load failed'
            }
          });
        });
    }
  }, [appName, language]);

  return React.useMemo(
    () => ({
      state,
      actions: {
        onOpen: () => {
          const { allAppNotices } = state;
          const billingAppNotices = getBillingAppNotices(allAppNotices);
          if (appName === AppName.Home) {
            dispatch({
              type: 'OPEN_MODAL_DIALOG',
              payload: {
                appNotices: billingAppNotices
              }
            });
          } else {
            dispatch({
              type: 'OPEN_MODAL_DIALOG',
              payload: {
                appNotices: allAppNotices
              }
            });
          }
        },
        onClose: () => {
          const { appNoticesToShow } = state;
          const isShowingBillingAppNotices = appNoticesToShow.some(
            ({ category }) => category === 'billing'
          );

          if (isShowingBillingAppNotices && appName !== AppName.Home) {
            dispatch({
              type: 'CLOSE_MODAL_DIALOG',
              payload: { appNotices: [], appName }
            });
            dispatch({
              type: 'SHOW_NON_BILLING_APP_NOTICES',
              payload: { appName }
            });
          } else {
            dispatch({
              type: 'CLOSE_MODAL_DIALOG',
              payload: { appNotices: [], appName }
            });
          }
        },
        toggleAgreement: () => {
          dispatch({ type: 'TOGGLE_AGREEMENT' });
        }
      }
    }),
    [appName, state]
  );
};
