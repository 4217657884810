import {
  getAxios,
  registerAuthListener
} from '@tablecheck/react-sso-authenticator';
import * as React from 'react';
import { useUnmount } from 'react-use';

import type { ReportSentryErrorType } from '../sentry';

import { AppTheme, AppThemeFunction, themeLocalStorageKey } from './constants';
import { useAppTheme, getCurrentAppTheme } from './exports';

const listeners: AppThemeFunction[] = [];
const persistanceListeners: typeof listeners = [];

export function useAppThemePersistance(
  reportSentryError: ReportSentryErrorType,
  userUrl = `${CONFIG.apiPath}current_user`
): void {
  React.useEffect(() => {
    const callback: AppThemeFunction = (theme) => {
      if (theme === getCurrentAppTheme()) return;
      getAxios()
        .put(userUrl, {
          app_theme: theme
        })
        .catch((error) => {
          console.error(error);
          reportSentryError(error, {
            extra: {
              info: 'Update user app_theme failed'
            }
          });
        });
    };
    persistanceListeners.push(callback);
    return () => {
      const index = persistanceListeners.indexOf(callback);
      if (index === -1) return;
      persistanceListeners.splice(index, 1);
    };
  }, [userUrl]);
}

if (!window.registerAppThemeListener)
  window.registerAppThemeListener = (listener) => {
    listeners.push(listener);
    return () => {
      listeners.splice(listeners.indexOf(listener), 1);
    };
  };

let setCurrentAppThemeRef: AppThemeFunction = () => {
  // as we set the current theme on the logged in user, this should be set after the user is logged in
  throw new Error(
    `set current app theme is called before the user is logged in`
  );
};

export const setCurrentAppTheme: AppThemeFunction = (...args) =>
  setCurrentAppThemeRef(...args);

let clearPreviewThemeDebounce: NodeJS.Timeout;

export function clearPreview(): void {
  clearTimeout(clearPreviewThemeDebounce);
}

function castToTheme(value: string | null | undefined): AppTheme | undefined {
  if (value && ['classic', 'dark', 'light'].includes(value))
    return value as AppTheme;
  return undefined;
}

window.addEventListener('storage', (event) => {
  const { key, newValue } = event;
  const value = castToTheme(newValue);
  if (key !== themeLocalStorageKey || !value) return;
  listeners.forEach((listener) => listener(value, false));
});

// setup the set function once the user is correctly logged in
registerAuthListener(() => {
  setCurrentAppThemeRef = (theme, isPreview) => {
    clearPreview();
    if (!isPreview) {
      persistanceListeners.forEach((listener) => listener(theme, isPreview));
      window.localStorage.setItem(themeLocalStorageKey, theme);
    }
    listeners.forEach((listener) => listener(theme, isPreview));
  };
});

export function usePreviewAppTheme(): {
  setPreviewTheme: (appTheme: AppTheme) => void;
  clearPreviewTheme: VoidFunction;
  activeTheme: AppTheme;
} {
  const activeTheme = useAppTheme(true);
  const returnValue = React.useMemo(
    () => ({
      activeTheme,
      setPreviewTheme: (newTheme: AppTheme) => {
        setCurrentAppThemeRef(newTheme, true);
      },
      clearPreviewTheme: () => {
        clearPreview();
        clearPreviewThemeDebounce = setTimeout(() => {
          setCurrentAppThemeRef(activeTheme, true);
        }, 350);
      }
    }),
    [activeTheme]
  );
  useUnmount(returnValue.clearPreviewTheme);
  return returnValue;
}
