import * as React from 'react';
import { TFunction } from 'react-i18next';

import { AppNoticeTitle } from 'components/AppNoticesModal/components/AppNoticeTitle';
import { AppNoticeBody } from 'components/AppNoticesModal/styles';
import { AppNoticeAPI } from 'components/AppNoticesModal/types';
import { renderElement } from 'components/AppNoticesModal/utils';

export function AppNotice({
  appNotice,
  t
}: {
  appNotice: AppNoticeAPI;
  t: TFunction;
}): JSX.Element {
  const {
    id,
    category,
    app_notice_sections: appNoticeSections,
    start_date: startDate
  } = appNotice;

  return (
    <AppNoticeBody key={id}>
      <AppNoticeTitle category={category} startDate={startDate} t={t} />
      {(appNoticeSections || []).map((element) => (
        <React.Fragment key={element.id}>
          {renderElement(element, t)}
        </React.Fragment>
      ))}
    </AppNoticeBody>
  );
}
