import { getAppPath } from 'exports';
import { AppName } from 'utils/constants';

import { SspaApp } from './base';

export const insightApp = new SspaApp({
  name: AppName.Insight,
  defineName: 'nexus',
  label: `portal:navigation.apps.${AppName.Insight}`,
  path: getAppPath('insight'),
  manifestHost: CONFIG.spaConfig.insight
});
