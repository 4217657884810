import styled from '@emotion/styled';
import { LogoSymbol } from '@tablecheck/tablekit-logo';

import { Breakpoint } from 'components/TopNav/constants';

import { NavItemButton } from '../styled';

const gap = 6;

export const Logo = styled(LogoSymbol)`
  color: ${({ theme }) => theme.colors.appSwitcherClosed};
`;

export const LogoNavItem = styled(NavItemButton)`
  display: flex;
  align-items: center;
  padding: 6px 22px;
  &[data-state='open'] > ${Logo} {
    color: ${({ theme }) => theme.colors.appSwitcherOpen};
  }
`;

export const ItemsWrapper = styled.div`
  display: flex;
  padding: ${gap}px;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const SubTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;

  @media (max-width: ${Breakpoint.Mobile}) {
    width: 100px;
  }
`;
