import { AppTheme } from 'utils/appTheme/exports';

import { Abilities } from './Ability';

export const SUPER_ROLES_BASIC = [
  'read_only',
  'manager',
  'analytics',
  'power_user'
] as const;
export const SUPER_ROLES_ADMIN = ['admin', 'root_admin'] as const;
export const SUPER_ROLES = [
  ...SUPER_ROLES_BASIC,
  ...SUPER_ROLES_ADMIN
] as const;
export type SuperRole = (typeof SUPER_ROLES)[number];

export type MerchantUserRole = {
  id: string;
  rank: number;
  modify_reservations?: boolean;
  modify_reservation_tables?: boolean;
  unset_reservation_tables?: boolean;
  lock_reservation_tables?: boolean;
  modify_blockages?: boolean;
  modify_manager_blockages?: boolean;
  modify_allocations?: boolean;
  modify_stoppages?: boolean;
  modify_customers?: boolean;
  view_customer_contacts?: boolean;
  merge_customers?: boolean;
  destroy_customers?: boolean;
  modify_settings?: boolean;
  modify_online?: boolean;
  modify_table_priorities?: boolean;
  modify_turnovers?: boolean;
  modify_paces?: boolean;
  modify_providers?: boolean;
  modify_reservation_flags?: boolean;
  destroy_reservation_flags?: boolean;
  view_memberships?: boolean;
  modify_memberships?: boolean;
  view_payments?: boolean;
  modify_payments?: boolean;
  capture_payments?: boolean;
  refund_payments?: boolean;
  void_payments?: boolean;
  view_analytics?: boolean;
  view_analytics_pii?: boolean;
  modify_analytics?: boolean;
  modify_surveys?: boolean;
  modify_users?: boolean;
  modify_user_passwords?: boolean;
  modify_franchise?: boolean;
  modify_roles?: boolean;
  modify_billing?: boolean;
  enable_cti?: boolean;
  export_customers?: boolean;
  export_customers_full?: boolean;
  export_daily_pack?: boolean;
  export_mailmag?: boolean;
  export_other?: boolean;
  anonymize_data?: boolean;
  send_shop_notice?: boolean;
  skip_reservation_iou?: boolean;
  import_data?: boolean;
  dev_tools?: boolean;
  modify_settings_advanced?: boolean;
  modify_security?: boolean;
  modify_menu_templates?: boolean;
  modify_themes?: boolean;
};

// https://gitlab.com/tablecheck/monolith/tablesolution/-/blob/develop/engines/vesper_core/app/mixins/vesper/core/person.rb#L10
export enum Gender {
  Unknown,
  Male,
  Female
}

export type ApiImageSize = 'lg' | 'md' | 'original' | 'sm' | 'xl' | 'xs';

interface ComputedMerchantUser {
  initials: string;
  is_admin: boolean;
  is_root_admin: boolean;
  merchant_role?: MerchantUserRole;
}

export interface ApiMerchantUser {
  ability: Partial<Abilities>;
  accessible_shop_ids: string[];
  app_theme: AppTheme;
  avatar_content_type?: string;
  avatar_dimensions?: Record<ApiImageSize, [number, number]>;
  avatar_file_size?: number;
  avatar_fingerprint?: string;
  avatar_updated_at?: string;
  avatar_urls?: Record<ApiImageSize, string>;
  can_access_all_franchises?: boolean;
  can_access_all_shops?: boolean;
  can_access_multiple_franchises?: boolean;
  can_view_analytics?: boolean;
  clock_mode: 'none' | '24h' | '12h';
  created_at: string;
  email: string;
  first_name: string;
  franchise_id: string;
  gender: Gender;
  id: string;
  last_name: string;
  locale: string;
  merchant_role_id: string;
  merchant_shop_roles: string[];
  name: string;
  phone: string | null;
  primary_shop_id?: string | null;
  super_role?: SuperRole | null;
  updated_at: string;
}

export type MerchantUser = Omit<ApiMerchantUser, 'merchant_role_id'> &
  ComputedMerchantUser;
