// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @tablecheck/max-mixed-css */
import { Logout } from '@carbon/icons-react';
import styled from '@emotion/styled';
import { useAuthObject } from '@tablecheck/react-sso-authenticator';
import { Button, ButtonGroup } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { UserAvatar } from 'components/TopNav/UserPanel/UserAvatar';
import { MerchantUser } from 'types/MerchantUser';
import { APP_THEMES } from 'utils/appTheme/constants';

import { ThemeButton } from './ThemeButton';

interface PropsType {
  user: MerchantUser | null | undefined;
  avatarFile?: File;
  setAvatarFile: (file: File | undefined) => void;
}

const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l7);
  align-self: stretch;
  align-items: center;
  justify-items: center;
  padding: var(--spacing-l7) 0 var(--spacing-l5);
  overflow: auto;
`;

const Panel = styled.div`
  padding: 0 var(--spacing-l4) var(--spacing-l7);
  overflow: auto;
  display: flex;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
`;

const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l5);
`;

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  text-align: center;
`;

const ButtonGrid = styled(ButtonGroup)`
  display: grid;
  grid-template-columns: repeat(3, max-content);
`;

const VerticalSpacer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-l2);
`;

function ThemeSelector(): JSX.Element {
  const [t] = useTranslation();
  return (
    <VerticalSpacer>
      <label htmlFor="#theme_selector">{t('settings:theme_mode_title')}</label>
      <ButtonGrid id="theme_selector">
        {APP_THEMES.map((theme) => (
          <ThemeButton key={theme} theme={theme} />
        ))}
      </ButtonGrid>
    </VerticalSpacer>
  );
}

export function UserPanelContent({
  user,
  avatarFile,
  setAvatarFile
}: PropsType): JSX.Element | null {
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const [t] = useTranslation();
  const authObject = useAuthObject();

  if (!user) return null;

  return (
    <Panel>
      <PanelContent>
        <UserContent>
          <UserAvatar
            user={user}
            avatarFile={avatarFile}
            setAvatarFile={setAvatarFile}
          />
          <UserDetails>
            <h3>{user.name}</h3>
            <small>{user.email}</small>
          </UserDetails>
        </UserContent>
        <ThemeSelector />
      </PanelContent>
      <Button
        onClick={() => {
          if (isLoggingOut) return;
          setIsLoggingOut(true);
          authObject.logout().catch(() => {
            // something failed in the logout
            setIsLoggingOut(false);
          });
        }}
        aria-busy={isLoggingOut}
        data-variant="ghost"
      >
        <Logout size={20} />
        {t('actions:sign_out')}
      </Button>
    </Panel>
  );
}
