import axios, { AxiosResponse } from 'axios';

import {
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskArticlesListResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const loadArticlesAction = ({
  locale,
  sectionId,
  dispatch
}: {
  dispatch: (action: HelpCenterReducerActions) => void;
  locale: string;
  sectionId: number;
}): ((message: string) => void) => {
  const source = axios.CancelToken.source();
  dispatch({
    type: HelpCenterAction.SetArticlesLoadingState,
    payload: {
      isLoadingArticles: true
    }
  });
  axios
    .get(`${ZENDESK_URL}help_center/${locale}/sections/${sectionId}/articles`, {
      cancelToken: source.token
    })
    .then(
      ({
        data: { articles }
      }: AxiosResponse<ZendeskArticlesListResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetArticlesList,
          payload: {
            articles
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetArticlesList
        }
      });
      dispatch({
        type: HelpCenterAction.SetArticlesLoadingState,
        payload: {
          isLoadingArticles: false
        }
      });
    });
  return (message: string) => {
    dispatch({
      type: HelpCenterAction.SetArticlesList,
      payload: {
        articles: []
      }
    });
    source.cancel(message);
  };
};
