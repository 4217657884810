import { ChevronRight } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { navigateToUrl } from 'single-spa';

import insightImage from 'assets/img/insight.png';
import managerImage from 'assets/img/manager.png';
import settingsImage from 'assets/img/settings.png';
import {
  AppButton,
  AppImage,
  CardFooter,
  Desc,
  Wording
} from 'scenes/LandingPage/styled';
import { getAppPath } from 'utils/appLink';
import { AppName } from 'utils/constants';

const appImages = {
  [AppName.Manager]: managerImage,
  [AppName.Insight]: insightImage,
  [AppName.Settings]: settingsImage
} as const;

export function AppCard({
  appName
}: {
  appName: Extract<
    AppName,
    AppName.Insight | AppName.Manager | AppName.Settings
  >;
}): JSX.Element {
  const [t] = useTranslation();

  const href =
    CONFIG.featureFlags.legacySettings && appName === AppName.Settings
      ? getAppPath('legacy-settings')
      : getAppPath(appName);

  return (
    <AppButton href={href} onClick={navigateToUrl}>
      <AppImage
        src={appImages[appName]}
        alt={t(`portal.dashboards.${appName}.name`)}
      />
      <Wording>{t(`portal:dashboards.${appName}.name`)}</Wording>
      <Desc>{t(`portal:dashboards.${appName}.description`)}</Desc>
      <CardFooter>
        <span>{t('actions:launch_app')}</span>
        <ChevronRight size={16} />
      </CardFooter>
    </AppButton>
  );
}
