import { Button, ButtonShape } from '@tablecheck/tablekit-button';
import { useTranslation } from 'react-i18next';

import { Footer } from 'components/TopNav/HelpCenter/styled';

export function FormFooter({
  onSubmit,
  isDisabled,
  isSubmittingForm
}: {
  isDisabled: boolean;
  isSubmittingForm: boolean;
  onSubmit: () => void;
}): JSX.Element {
  const [t] = useTranslation();
  return (
    <Footer>
      <Button
        shape={ButtonShape.Circular}
        onClick={onSubmit}
        isDisabled={isDisabled}
        isLoading={isSubmittingForm}
        type="button"
      >
        {t('portal:help.help_center_send_form')}
      </Button>
    </Footer>
  );
}
