import * as React from 'react';

import { AppContext as AppContextType, AppStateEvents } from 'scenes/types';
import { MerchantUser } from 'types/MerchantUser';
import { getInitials } from 'utils/currentUser';

export const getDefaultAppContext = (): {
  context: AppContextType;
  actions: { updateUser: (user: MerchantUser) => void };
} => ({
  context: {
    state: 'init'
  },
  actions: {
    updateUser: () => {}
  }
});

export const AppContext = React.createContext<{
  context: AppContextType;
  actions: { updateUser: (user: MerchantUser) => void };
} | null>(null);

export const appReducer = (
  previousContext: AppContextType,
  event: AppStateEvents
): AppContextType => {
  if (event.type === 'LOAD_APP') {
    return {
      state: 'app_transition',
      merchantUser: previousContext.merchantUser
    };
  }
  if (event.type === 'APP_LOADED') {
    return { state: 'ready', merchantUser: previousContext.merchantUser };
  }
  if (event.type === 'INIT') {
    return { state: 'loading', merchantUser: previousContext.merchantUser };
  }
  if (event.type === 'ERROR' || event.type === 'AUTH_ERROR') {
    return {
      state: 'error',
      error: event.error,
      isAuthError: event.type === 'AUTH_ERROR',
      merchantUser: previousContext.merchantUser
    };
  }
  if (event.type === 'USER_LOADED') {
    return {
      state: 'ready',
      merchantUser: event.merchantUser
    };
  }
  if (event.type === 'USER_UPDATED') {
    const { merchantUser } = event;
    merchantUser.initials = getInitials(merchantUser.name);

    return {
      state: 'ready',
      merchantUser
    };
  }
  return previousContext;
};

export const useAppContext = (): {
  context: AppContextType;
  actions: { updateUser: (user: MerchantUser) => void };
} => {
  const contextValue = React.useContext(AppContext);

  if (!contextValue) {
    throw new Error('Trying to access AppContext outside provider!');
  }

  return contextValue;
};
