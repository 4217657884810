import {
  registerAuthListener,
  getAxios,
  getAxiosFetch
} from '@tablecheck/react-sso-authenticator';

import { SspaAppProps } from 'apps/types';

export function registerOnAuthChangeListener(
  listener: Parameters<SspaAppProps['onAuthChange']>[0]
): VoidFunction {
  return registerAuthListener((authObject) => {
    listener({
      authObject,
      axios: getAxios(),
      axiosFetch: getAxiosFetch()
    });
  });
}
