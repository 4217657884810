import styled from '@emotion/styled';

import { AppNoticeVideoSectionAPI } from 'components/AppNoticesModal/types';

export const YoutubeVideoWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border-radius: var(--border-radius-small);
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

export function Video({
  element
}: {
  element: AppNoticeVideoSectionAPI;
}): JSX.Element {
  const { cta_link_url: linkUrl, content_text: description } = element;

  return (
    <YoutubeVideoWrapper>
      <iframe
        title={description}
        src={linkUrl}
        allow="autoplay; encrypted-media"
        allowFullScreen
      />
    </YoutubeVideoWrapper>
  );
}
