import { useTheme } from '@emotion/react';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { BannerAppearance } from '@tablecheck/tablekit-banner';
import { Icon } from '@tablecheck/tablekit-icon';
import { Size } from '@tablecheck/tablekit-theme';
import * as React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { Attachment } from 'components/TopNav/HelpCenter/elements/Attachment';
import {
  DropzoneContainer,
  DropzoneArea,
  FieldLabel,
  StyledBanner
} from 'components/TopNav/HelpCenter/styled';

export function FilesUploader(): JSX.Element {
  const [t] = useTranslation();
  const theme = useTheme();
  const [files, setFiles] = React.useState<File[]>([]);
  const [error, setError] = React.useState<string>();
  const {
    actions: { resetFiles = () => {} }
  } = React.useContext(HelpCenterContext);
  React.useEffect(
    () => () => {
      resetFiles();
    },
    [resetFiles]
  );
  const onDrop = React.useCallback<Required<DropzoneOptions>['onDrop']>(
    (acceptedFiles) => {
      let newFiles = [...files, ...acceptedFiles];
      if (newFiles.length > 5) {
        setError(t('portal:help.errors.files_limit'));
        newFiles = newFiles.slice(0, 5);
      }
      setFiles(newFiles);
    },
    [files, t]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const removeFile = (file: File) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  return (
    <>
      <FieldLabel>
        {`${t('portal:help.attachments')} ${
          files.length ? `(${files.length})` : ''
        }`}
      </FieldLabel>
      <DropzoneContainer>
        <DropzoneArea {...getRootProps()}>
          <input {...getInputProps()} />
          <p>
            <Icon
              icon={faPaperclip}
              color={theme.colors.primary}
              size={Size.Large}
            />
            {t(`portal:help.${isDragActive ? 'drop_files' : 'add_files'}`)}
          </p>
        </DropzoneArea>
        {error ? (
          <StyledBanner
            appearance={BannerAppearance.Danger}
            message={error}
            isDismissible
            onClose={() => setError('')}
          />
        ) : null}
        {files.map((file, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Attachment key={index} file={file} removeFile={removeFile} />
        ))}
      </DropzoneContainer>
    </>
  );
}
