import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons/faExternalLinkAlt';
import {
  AnchorButton,
  ButtonAppearance,
  ButtonShape
} from '@tablecheck/tablekit-button';
import { Icon } from '@tablecheck/tablekit-icon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  getZendeskLocale,
  HelpCenterContext
} from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import {
  HelpCenterScreen,
  ZENDESK_HELP_CENTER_URL
} from 'components/TopNav/HelpCenter/keys';

export function HelpCenterLink(): JSX.Element | null {
  const {
    helpScreen,
    article,
    isLoadingArticle,
    categoryId,
    categories,
    isLoadingSections,
    isLoadingCategories,
    sectionId,
    sections
  } = React.useContext(HelpCenterContext);
  const [, { language }] = useTranslation();
  const locale = getZendeskLocale(language);
  let href = `${ZENDESK_HELP_CENTER_URL}${locale}`;
  if (helpScreen === HelpCenterScreen.Article && !isLoadingArticle && article) {
    href = article.html_url;
  } else if (
    helpScreen === HelpCenterScreen.Section &&
    !isLoadingSections &&
    sections &&
    sectionId
  ) {
    const section = sections.find(({ id }) => sectionId === id);
    if (section) {
      href = section.html_url;
    }
  } else if (
    helpScreen === HelpCenterScreen.SectionsList &&
    !isLoadingCategories &&
    categories
  ) {
    const category = categories.find(({ id }) => categoryId === id.toString());
    if (category) {
      href = category.html_url;
    }
  }

  return href ? (
    <AnchorButton
      appearance={ButtonAppearance.Subtle}
      shape={ButtonShape.Circular}
      iconBefore={<Icon icon={faExternalLinkAlt} />}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    />
  ) : null;
}
