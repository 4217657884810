import { InputSize } from '@tablecheck/tablekit-input';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { HelpCenterSearchBox } from 'components/TopNav/HelpCenter/styled';

export function HelpCenterSearch(): JSX.Element | null {
  const { search, articleId, categoryId, sectionId, isContactForm } =
    React.useContext(HelpCenterContext);
  const history = useHistory();
  const [searchText, changeSearchText] = React.useState<string>(search || '');
  React.useEffect(() => {
    changeSearchText(search || '');
  }, [changeSearchText, search]);

  const onChange = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      const {
        currentTarget: { value }
      } = event;
      changeSearchText(value);
      if (!value) {
        history.replace(
          `?view=help${articleId ? `&articleId=${articleId}` : ''}${
            sectionId ? `&sectionId=${sectionId}` : ''
          }${categoryId ? `&categoryId=${categoryId}` : ''}`
        );
      }
    },
    [history, articleId, sectionId, categoryId]
  );

  const onKeyPress = React.useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    ({ keyCode, which, key }) => {
      if (keyCode === 13 || which === 13 || key === 'Enter') {
        history.replace(
          searchText
            ? `?view=help${
                categoryId ? `&categoryId=${categoryId}` : ''
              }&search=${searchText.trim()}${
                categoryId ? '' : '&isGlobalSearch=true'
              }`
            : `?view=help${articleId ? `&articleId=${articleId}` : ''}${
                sectionId ? `&sectionId=${sectionId}` : ''
              }${categoryId ? `&categoryId=${categoryId}` : ''}`
        );
      }
    },
    [history, searchText, categoryId, articleId, sectionId]
  );

  if (isContactForm) return null;

  return (
    <HelpCenterSearchBox
      type="search"
      size={InputSize.Small}
      isMessageHidden
      shouldFitContainer
      value={searchText}
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  );
}
