import * as React from 'react';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import { Article } from 'components/TopNav/HelpCenter/screens/Article';
import { ArticlesList } from 'components/TopNav/HelpCenter/screens/ArticlesList';
import { CategoriesList } from 'components/TopNav/HelpCenter/screens/CategoriesList';
import { ContactUsForm } from 'components/TopNav/HelpCenter/screens/ContactUsForm';
import { MessageSent } from 'components/TopNav/HelpCenter/screens/MessageSent';
import { SearchResults } from 'components/TopNav/HelpCenter/screens/SearchResults';
import { SectionsList } from 'components/TopNav/HelpCenter/screens/SectionsList';

export const HelpRouter = (): JSX.Element | null => {
  const {
    helpScreen,
    categoryId,
    actions: { loadCategories, loadSections }
  } = React.useContext(HelpCenterContext);
  React.useEffect(() => {
    const cancelRequest = loadCategories();
    return () => cancelRequest();
  }, [loadCategories]);

  React.useEffect(() => {
    if (!categoryId) return () => {};
    return loadSections(categoryId);
  }, [categoryId, loadSections]);

  return React.useMemo(() => {
    switch (helpScreen) {
      case HelpCenterScreen.SectionsList:
        return <SectionsList />;
      case HelpCenterScreen.Search:
        return <SearchResults />;
      case HelpCenterScreen.Section:
        return <ArticlesList />;
      case HelpCenterScreen.Article:
        return <Article />;
      case HelpCenterScreen.CategoriesList:
        return <CategoriesList />;
      case HelpCenterScreen.ContactUsForm:
        return <ContactUsForm />;
      case HelpCenterScreen.MessageSent:
        return <MessageSent />;
      default:
        return null;
    }
  }, [helpScreen]);
};
