import styled from '@emotion/styled';
import { Button } from '@tablecheck/tablekit-react';

import { AppNoticeButtonSectionAPI } from 'components/AppNoticesModal/types';

export const LinkButton = styled(Button.withComponent('a'))<{
  isStatic?: boolean;
}>`
  width: 100%;
  pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'auto')};
  text-decoration: none;
`;

export function CTAButton({
  element,
  placeholder,
  isStatic
}: {
  element: AppNoticeButtonSectionAPI;
  placeholder?: string;
  isStatic?: boolean;
}): JSX.Element {
  const {
    cta_button_text: text,
    cta_button_type: type,
    cta_link_url: linkUrl
  } = element;

  return (
    <LinkButton
      href={linkUrl}
      target="_blank"
      rel="noreferrer"
      data-variant={type === 'secondary' ? 'ghost' : 'secondary'}
      data-size="large"
      isStatic={isStatic}
    >
      {text || placeholder}
    </LinkButton>
  );
}
