import styled from '@emotion/styled';
import { Panel, ResizablePanel } from '@tablecheck/tablekit-panel';
import { DEPTH, Spacing, ZIndex } from '@tablecheck/tablekit-theme';
import { Typography } from '@tablecheck/tablekit-typography';
import { margin } from '@tablecheck/tablekit-utils';

import {
  portalClassicTheme,
  portalDarkTheme,
  portalLightTheme
} from 'App/themes';
import { portalThemeNamespace } from 'App/types';
import { Avatar } from 'components/TopNav/Avatar';
import { Breakpoint } from 'components/TopNav/constants';
import { AppTheme } from 'utils/appTheme/constants';

export const PositionedTopNav = styled.div<{ 'data-theme': AppTheme }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.Topnav};
  min-height: var(--portal-header-height);
  width: 100%;
  box-shadow: ${DEPTH.OVERLAY.boxShadow};
  display: grid;
  grid-template-columns:
    74px
    minmax(0, calc(228px - 74px))
    minmax(0, auto)
    max-content
    minmax(0, auto)
    228px;

  &,
  &[data-theme='classic'] {
    background-color: ${portalClassicTheme.colors.navbarBackground};
    color: ${portalClassicTheme.colors.navbarForeground};
    --portal-button-background: hsl(0, 0%, 29%);
    --portal-button-foreground: #e7e7e9;
    --portal-button-background-hover: hsl(0, 0%, 39%);
  }
  &[data-theme='light'] {
    background-color: ${portalLightTheme.colors.navbarBackground};
    color: ${portalLightTheme.colors.navbarForeground};
    --portal-button-background: hsl(266, 67%, 95%);
    --portal-button-foreground: #323232;
    --portal-button-background-hover: hsl(266, 67%, 85%);
  }
  &[data-theme='dark'] {
    background-color: ${portalDarkTheme.colors.navbarBackground};
    color: ${portalDarkTheme.colors.navbarForeground};
    --portal-button-background: hsl(0, 0%, 29%);
    --portal-button-foreground: #e7e7e9;
    --portal-button-background-hover: hsl(0, 0%, 39%);
  }
`;

const NavBarContainer = styled.div`
  height: var(--portal-header-height);
  display: flex;
  align-items: center;
  flex: 1 1 auto;
`;

export const LeftContainer = styled(NavBarContainer)`
  justify-content: flex-start;
  grid-column: 1 / span 1;
`;

export const CenterContainer = styled(NavBarContainer)`
  grid-column: 4 / span 1;
  &[data-variant='full'] {
    grid-column: 2 / span 4;
  }
`;

export const RightContainer = styled(NavBarContainer)`
  justify-content: flex-end;
  grid-column: 6 / span 1;
`;

export const ItemGroupWrapper = styled.div`
  &,
  & > * {
    height: 100%;
  }
  display: flex;
  align-items: center;
`;

export const UserPanel = styled(Panel)`
  top: var(--portal-header-height);
  height: calc(100vh - var(--portal-header-height)) !important;
`;

export const ResizablePanelContainer = UserPanel.withComponent(ResizablePanel);

export const TopNavAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.primarySelected};
  color: currentColor;
`;

export const LangWrapper = styled.div`
  position: relative;
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${Breakpoint.Mobile}) {
    width: 20px;
    height: 20px;
    border: none;
  }
`;

export const SelectedLang = styled.div`
  ${Typography.Body2};
  ${margin({ right: Spacing.L2 })}
`;

export const NavItemButton = styled.button<{
  'data-selected'?: boolean;
  width?: string;
}>`
  height: 100%;
  width: ${({ width }) => width || '50px'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Spacing.L3};
  color: ${({ theme }) => theme[portalThemeNamespace].colors.navbarForeground};
  border: 2px solid transparent;
  cursor: pointer;
  position: relative;
  &:hover,
  &:focus {
    background: ${({ theme }) =>
      theme[portalThemeNamespace].colors.navbarBackgroundHovered};
    color: ${({ theme }) =>
      theme[portalThemeNamespace].colors.navbarForegroundHovered};
    outline: none;
    text-decoration: none;
  }
  &:focus:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.focusOutline};
  }
  &[data-selected='true'],
  &[data-state='open'] {
    border-top-color: ${({ theme }) =>
      theme[portalThemeNamespace].colors.navbarSelectedBorder};
    color: ${({ theme }) =>
      theme[portalThemeNamespace].colors.navbarForegroundSelected};
    background-color: ${({ theme }) =>
      theme[portalThemeNamespace].colors.navbarBackgroundSelected};
  }
`;

export const NavItemLink = NavItemButton.withComponent('a');
