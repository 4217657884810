import { locales } from '@tablecheck/locales';
import * as React from 'react';

import {
  HelpCenterContextType,
  HelpCenterState,
  SetHelpScreenPayload,
  HelpCenterReducerActions,
  HelpCenterAction,
  FileUploadPayload
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import { MerchantUser } from 'types/MerchantUser';

export const ITEMS_PER_PAGE = 100;

export const getZendeskLocale = (lang: string = locales.en.code): string => {
  const localesMap = {
    [locales.en.code]: 'en-us',
    [locales.ja.code]: 'ja'
  };
  return localesMap[lang] || localesMap[locales.en.code];
};

export const getHelpCenterContext = (): HelpCenterState => ({
  anchor: null,
  article: null,
  articleId: null,
  articles: [],
  categoryId: null,
  categories: [],
  files: [],
  uploadingFiles: [],
  helpScreen: HelpCenterScreen.SectionsList,
  isLoadingArticle: false,
  isLoadingArticles: false,
  isLoadingCategories: false,
  isLoadingSections: false,
  isMessageSent: false,
  isGlobalSearch: false,
  isSubmittingForm: false,
  search: null,
  sectionId: null,
  sections: [],
  isContactForm: false
});

export const HelpCenterContext = React.createContext<HelpCenterContextType>({
  ...getHelpCenterContext(),
  user: {
    email: '',
    id: '',
    initials: '',
    name: ''
  } as MerchantUser,
  actions: {
    resetFiles: () => {},
    loadArticle: () => () => {},
    loadArticles: () => () => {},
    loadCategories: () => () => {},
    loadSections: () => () => {},
    searchArticles: () => () => {},
    submitForm: () => {},
    uploadFile: () => {}
  }
});

const getHelpScreen = ({
  articleId,
  categoryId,
  isMessageSent,
  contactForm,
  search,
  sectionId
}: SetHelpScreenPayload): HelpCenterScreen => {
  if (isMessageSent) {
    return HelpCenterScreen.MessageSent;
  }
  if (contactForm) {
    return HelpCenterScreen.ContactUsForm;
  }
  if (articleId) {
    return HelpCenterScreen.Article;
  }
  if (sectionId) {
    return HelpCenterScreen.Section;
  }
  if (search) {
    return HelpCenterScreen.Search;
  }
  if (categoryId) {
    return HelpCenterScreen.SectionsList;
  }
  return HelpCenterScreen.CategoriesList;
};

const setUplodingFiles = (
  state: HelpCenterState,
  payload: FileUploadPayload
) => {
  const { uploadingFiles } = state;
  const newUploadingFiles = [...uploadingFiles];
  const { file } = payload;
  const index = newUploadingFiles.indexOf(file);
  if (index === -1) {
    newUploadingFiles.push(file);
  } else {
    newUploadingFiles.splice(index, 1);
  }
  return { ...state, uploadingFiles: newUploadingFiles };
};

export const helpCenterReducer = (
  state: HelpCenterState,
  action: HelpCenterReducerActions
): HelpCenterState => {
  switch (action.type) {
    case HelpCenterAction.SetCategoriesList:
      return {
        ...state,
        categories: action.payload.categories,
        isLoadingCategories: false
      };
    case HelpCenterAction.SetCategoriesListLoadingState:
      return {
        ...state,
        isLoadingCategories: action.payload.isLoadingCategories
      };
    case HelpCenterAction.SetArticle:
      return {
        ...state,
        article: action.payload.article,
        sectionId:
          (action.payload.article && action.payload.article.section_id) ||
          state.sectionId,
        isLoadingArticle: false
      };
    case HelpCenterAction.SetArticleLoadingState:
      return {
        ...state,
        isLoadingArticle: action.payload.isLoadingArticle
      };
    case HelpCenterAction.SetArticlesList:
      return {
        ...state,
        articles: action.payload.articles,
        isLoadingArticles: false
      };
    case HelpCenterAction.SetArticlesLoadingState:
      return {
        ...state,
        isLoadingArticles: action.payload.isLoadingArticles
      };
    case HelpCenterAction.SetFiles:
      return {
        ...state,
        isSubmittingForm: false,
        files: [...state.files, action.payload.fileToken]
      };
    case HelpCenterAction.SetUploadingFiles:
      return setUplodingFiles(state, action.payload);
    case HelpCenterAction.ResetFiles:
      return {
        ...state,
        files: [],
        uploadingFiles: []
      };
    case HelpCenterAction.SetFormSubmitingState:
      return {
        ...state,
        isSubmittingForm: action.payload.isSubmittingForm
      };
    case HelpCenterAction.SubmitForm:
      return {
        ...state,
        isSubmittingForm: false
      };
    case HelpCenterAction.SetSearchResults:
      return {
        ...state,
        isLoadingArticles: false,
        articles: action.payload.articles,
        sections: action.payload.sections || []
      };
    case HelpCenterAction.SetSectionsList:
      return {
        ...state,
        isLoadingSections: false,
        sections: action.payload.sections
      };
    case HelpCenterAction.SetSectionsListLoadingState:
      return {
        ...state,
        isLoadingSections: action.payload.isLoadingSections
      };
    case HelpCenterAction.SetScreen:
      return {
        ...state,
        helpScreen: getHelpScreen(action.payload),
        anchor: action.payload.anchor,
        articleId: action.payload.articleId,
        categoryId: action.payload.categoryId,
        isGlobalSearch: action.payload.isGlobalSearch,
        isMessageSent: action.payload.isMessageSent,
        isContactForm: action.payload.contactForm,
        sectionId: action.payload.sectionId,
        search: action.payload.search
      };
    default:
      return state;
  }
};
