import styled from '@emotion/styled';

import { portalThemeNamespace } from 'App/types';
import { Breakpoint } from 'components/TopNav/constants';

export const Wrapper = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 90px;

  @media (max-width: ${Breakpoint.Mobile}) {
    padding: 0 40px;
  }
`;

export const AppsGrid = styled.div`
  display: flex;
  gap: var(--spacing-l5);

  @media (max-width: ${Breakpoint.Mobile}) {
    display: block;
  }
`;

export const CardFooter = styled.div`
  grid-area: footer;
  align-self: flex-end;
  color: ${({ theme }) => theme[portalThemeNamespace].colors.textColor};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: color 0.3s ease-in-out;

  span {
    font: var(--label);
    margin-right: var(--spacing-l1);
  }

  svg {
    transition: transform 0.3s ease-in-out;
  }
`;

export const AppButton = styled.a`
  flex: 1;
  display: inline-grid;
  grid-template-areas:
    'image '
    'imageGap'
    'name'
    'nameGap'
    'description'
    'descriptionGap'
    'footer';
  grid-template-rows:
    max-content var(--spacing-l4) max-content var(--spacing-l2)
    max-content var(--spacing-l5) auto;
  text-decoration: none;
  padding: var(--spacing-l5);
  background-color: var(--surface);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: left;
  border: 1px solid var(--border);
  border-radius: var(--border-radius-large);

  @media (min-width: ${Breakpoint.Mobile}) {
    &:hover {
      transform: scale(1.01);
      background-color: var(--surface);

      ${CardFooter} {
        color: var(--text-subtle);

        svg {
          transform: translateX(2px);
        }
      }
    }
  }

  @media (max-width: ${Breakpoint.Mobile}) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: var(--spacing-l5);
    }
  }
`;

export const AppImage = styled.img`
  width: 100%;
  border: 1px solid var(--border);
  border-radius: var(--border-radius-large);
  aspect-ratio: 64 / 41;
`;

export const Wording = styled.h3`
  grid-area: name;
  flex-grow: 0;
  color: var(--text);
`;

export const Desc = styled.p`
  grid-area: description;
  flex-grow: 1;
  color: var(--text);
`;

export const MainHeading = styled.h2`
  padding: var(--spacing-l7) 0 var(--spacing-l5);
  color: var(--text);
`;
