import { Email } from '@carbon/icons-react';
import styled from '@emotion/styled';
import * as React from 'react';

import { AppNoticesModal } from 'components/AppNoticesModal';
import { useAppNotices } from 'components/AppNoticesModal/useAppNotices';
import { NavItemButton } from 'components/TopNav/styled';
import { MerchantUser } from 'types/MerchantUser';
import { useCurrentApp } from 'utils/useIsCurrentApp';

const IconWrapper = styled.div<{ showIndicator?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  &:after {
    content: '';
    display: ${({ showIndicator }) => (showIndicator ? 'block' : 'none')};
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    top: 0;
    right: -2px;
  }
`;

export function AppNoticesItem({
  userId
}: {
  userId: MerchantUser['id'];
}): JSX.Element | null {
  const currentApp = useCurrentApp();
  const {
    state: {
      allAppNotices,
      appNoticesToShow,
      hasNewNotices,
      hasAgreementAccepted,
      mode
    },
    actions: { onOpen, onClose, toggleAgreement }
  } = useAppNotices(currentApp, userId);
  const isShowingBillingAppNotices = mode === 'billing';
  const isCloseButtonDisabled =
    isShowingBillingAppNotices && !hasAgreementAccepted;

  if (!allAppNotices.length) {
    return null;
  }

  return (
    <>
      <NavItemButton onClick={onOpen}>
        <IconWrapper showIndicator={hasNewNotices}>
          <Email size={20} />
        </IconWrapper>
      </NavItemButton>
      <AppNoticesModal
        isOpen={!!appNoticesToShow.length}
        onClose={onClose}
        appNotices={appNoticesToShow}
        hasAgreementAccepted={hasAgreementAccepted}
        toggleAgreement={toggleAgreement}
        isShowingBillingAppNotices={isShowingBillingAppNotices}
        isCloseButtonDisabled={isCloseButtonDisabled}
      />
    </>
  );
}
