import { Alert, AlertAppearance } from '@tablecheck/tablekit-alert';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { reportSentryError } from 'utils/sentry';

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
};

function AlertMessage(): JSX.Element {
  const [t] = useTranslation();
  return (
    <Alert appearance={AlertAppearance.Warning} isShow shouldUsePortal>
      {t('portal:messages.maintenance')}
    </Alert>
  );
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    reportSentryError(error, {
      extra: { errorInfo }
    });
  }

  render(): JSX.Element | React.ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <AlertMessage />;
    }
    return children;
  }
}
