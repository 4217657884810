import { getAxios } from '@tablecheck/react-sso-authenticator';
import orderBy from 'lodash/orderBy';

import { AppNoticeAPI } from 'components/AppNoticesModal/types';
import { parseAppNotice } from 'components/AppNoticesModal/utils';
import { AppName } from 'utils/constants';

export const processAppNoticesData = (
  appNotices: AppNoticeAPI[]
): AppNoticeAPI[] => {
  const parsedAppNotices = appNotices.map((appNotice) =>
    parseAppNotice(appNotice)
  );
  return orderBy(
    parsedAppNotices,
    [
      ({ category }) => (category === 'billing' ? 1 : 0),
      ({ created_at: createdAt }) => new Date(createdAt).valueOf()
    ],
    ['desc', 'desc']
  );
};

export const loadAppNotices = async (
  appName: AppName,
  locale: string
): Promise<AppNoticeAPI[]> => {
  try {
    const {
      data: { app_notices_public: appNotices }
    } = await getAxios().post<{ app_notices_public: AppNoticeAPI[] }>(
      `${CONFIG.apiPath}app_notices_public`,
      {
        app: appName === AppName.Home ? 'portal' : appName,
        locale
      }
    );
    return processAppNoticesData(appNotices);
  } catch (error) {
    throw new Error(`Failed to load app notices for ${appName}`);
  }
};
