import axios from 'axios';
import { LifeCycles } from 'single-spa';

import { AppName } from 'utils/constants';
import { urlAmdImport as loader } from 'utils/urlAmdImport';

import { SspaAppProps } from './types';

interface MatchFunction {
  ({ pathname }: { pathname: string }): boolean;
}
interface LoadingFunction {
  (): Promise<LifeCycles<SspaAppProps>>;
}

interface PropsType {
  path: string;
  name: AppName;
  /**
   * The name postfix override used for including javascript from the loaded AMD bundle
   */
  defineName?: string;
  label: string;
  manifestHost?: string;
  matchFunction?: MatchFunction;
  loadingFunction?: LoadingFunction;
}

export class SspaApp implements PropsType {
  path: string;

  name: PropsType['name'];

  defineName?: PropsType['defineName'];

  label: string;

  manifestHost?: string;

  manifestPromise: Promise<Record<string, string>>;

  matchFunction: MatchFunction;

  internalLoadingFunction: LoadingFunction;

  constructor(props: PropsType) {
    this.path = props.path;
    this.name = props.name;
    this.defineName = props.defineName;
    this.manifestHost = props.manifestHost;
    this.label = props.label;
    this.matchFunction =
      props.matchFunction || (({ pathname }) => pathname.startsWith(this.path));
    this.internalLoadingFunction =
      props.loadingFunction || this.defaultLoadingFunction;
    if (this.manifestHost) {
      this.manifestPromise = this.loadManifest();
    } else if (props.loadingFunction) {
      this.manifestPromise = Promise.resolve({});
    } else {
      this.manifestPromise = Promise.reject(
        new Error(
          `No manifestHost or custom loadingFunction defined for ${this.name}`
        )
      );
    }
  }

  loadManifest(): Promise<Record<string, string>> {
    if (!this.manifestHost) return Promise.resolve({});
    // detect a relative path
    if (!/(http(|s):|)\/\//.test(this.manifestHost)) {
      this.manifestHost = `//${this.manifestHost}`;
    }
    this.manifestHost = this.manifestHost.replace(/\/$/, '');
    return axios
      .get(`${this.manifestHost}/manifest.json`)
      .then((response) => response.data);
  }

  loadingFunction(): ReturnType<LoadingFunction> {
    if (this.manifestPromise) {
      return this.manifestPromise
        .catch(() => this.loadManifest())
        .then(() => this.internalLoadingFunction());
    }
    return this.internalLoadingFunction();
  }

  defaultLoadingFunction(): ReturnType<LoadingFunction> {
    if (!this.manifestPromise) {
      throw new Error(
        `defaultLoadingFunction called with no manifestHost for ${this.name}`
      );
    }
    return this.manifestPromise.then((paths) => {
      if (!this.manifestHost) {
        throw new Error(
          `defaultLoadingFunction called with no manifestHost for ${this.name}`
        );
      }
      return loader(
        this.manifestHost,
        paths,
        `@tablesolution-next/${this.defineName || this.name}`
      );
    });
  }
}
