import styled from '@emotion/styled';
import { Spinner, SpinnerSize } from '@tablecheck/tablekit-spinner';

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export function Loader(): JSX.Element {
  return (
    <LoaderContainer>
      <Spinner size={SpinnerSize.Large} />
    </LoaderContainer>
  );
}
