import { Close } from '@carbon/icons-react';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, IconButton } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AppNotice } from 'components/AppNoticesModal/AppNotice';
import { BillingAgreement } from 'components/AppNoticesModal/components/BillingAgreement';
import { AppNoticeAPI } from 'components/AppNoticesModal/types';

import {
  AppNoticesContent,
  AppNoticesWrapper,
  DialogContent,
  DialogOverlay,
  Footer,
  Header,
  HeaderRow
} from './styles';

export function AppNoticesModal({
  isOpen,
  onClose,
  appNotices,
  hasAgreementAccepted,
  toggleAgreement,
  isShowingBillingAppNotices,
  isCloseButtonDisabled
}: {
  isOpen: boolean;
  onClose: () => void;
  appNotices: AppNoticeAPI[];
  hasAgreementAccepted: boolean;
  toggleAgreement: () => void;
  isShowingBillingAppNotices: boolean;
  isCloseButtonDisabled: boolean;
}): JSX.Element {
  const [t] = useTranslation();

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <DialogOverlay />
        <DialogContent>
          <AppNoticesContent>
            <Header>
              <HeaderRow>
                <h2>{t('portal:app_notice.title', { count: 1 })}</h2>
                {isShowingBillingAppNotices ? null : (
                  <IconButton
                    data-variant="bare"
                    data-size="small"
                    type="button"
                    onClick={onClose}
                  >
                    <Close size={16} />
                  </IconButton>
                )}
              </HeaderRow>
            </Header>
            <AppNoticesWrapper>
              {appNotices.map((appNotice) => (
                <AppNotice key={appNotice.id} appNotice={appNotice} t={t} />
              ))}
            </AppNoticesWrapper>
            {isShowingBillingAppNotices ? (
              <BillingAgreement
                hasAgreementAccepted={hasAgreementAccepted}
                toggleAgreement={toggleAgreement}
                t={t}
              />
            ) : null}
            <Footer>
              <Button
                onClick={onClose}
                data-size="large"
                disabled={isCloseButtonDisabled}
              >
                {t('actions:done')}
              </Button>
            </Footer>
          </AppNoticesContent>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
