import { HelpFilled } from '@carbon/icons-react';
import styled from '@emotion/styled';
import { Spacing } from '@tablecheck/tablekit-theme';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { useAppRegistry } from 'apps/Registry';
import { Popover } from 'components/Popover';
import { HELP_SECTION_BUTTON_ID } from 'components/TopNav/constants';

import { NavItemButton } from './styled';

interface Props {
  isSelected: boolean;
  toggleWidget: (forcedState?: boolean) => void;
}

const Header = styled.h4`
  padding: ${Spacing.L4};
  padding-bottom: ${Spacing.L2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
`;

const Container = styled.div`
  padding: ${Spacing.L4};
  padding-top: ${Spacing.L2};
  color: ${({ theme }) => theme.colors.text};
`;

export function HelpItem({ isSelected, toggleWidget }: Props): JSX.Element {
  const [appRegistry] = useAppRegistry();
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      <Popover.Anchor asChild>
        <NavItemButton
          id={HELP_SECTION_BUTTON_ID}
          onClick={(event: React.MouseEvent) => {
            if (isOpen || event.altKey) {
              setIsOpen(!isOpen);
            } else {
              toggleWidget();
            }
          }}
          data-selected={isSelected}
        >
          <HelpFilled size={20} />
        </NavItemButton>
      </Popover.Anchor>
      <Popover.Content
        onOpenAutoFocus={(event) => event.preventDefault()}
        align="end"
      >
        <Header>{t('settings:app_version')}</Header>
        <Container>
          <p>
            <strong>{t('portal:navigation.apps.portal')}:</strong>{' '}
            {CONFIG.appVersion}
          </p>
          {appRegistry.map(({ name, version }) => (
            <p key={name}>
              <strong>{t(`portal:navigation.apps.${name}`)}:</strong> {version}
            </p>
          ))}
        </Container>
      </Popover.Content>
    </Popover.Root>
  );
}
