import styled from '@emotion/styled';
import * as React from 'react';

import { AppNoticeImageSectionAPI } from 'components/AppNoticesModal/types';

import { Image as Img } from './styles';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 120px;

  &[data-variant='placeholder'] {
    border: 1px solid var(--border);
    border-radius: var(--border-radius-small);
    background-color: var(--grey-100);
  }
`;

const Placeholder = styled.span`
  font: var(--label);
  color: var(--text-placeholder);
`;

export function Image({
  element,
  imagePreview,
  placeholder
}: {
  element: AppNoticeImageSectionAPI;
  imagePreview?: string;
  placeholder?: string;
}): JSX.Element {
  const { cta_link_url: linkUrl } = element;

  return (
    <Wrapper data-variant={imagePreview ? undefined : 'placeholder'}>
      {imagePreview ? (
        <a href={linkUrl || undefined} target="_blank" rel="noreferrer">
          <Img src={imagePreview} />
        </a>
      ) : (
        <Placeholder>
          <span>{placeholder}</span>
        </Placeholder>
      )}
    </Wrapper>
  );
}
