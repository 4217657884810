import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faComment } from '@fortawesome/pro-regular-svg-icons/faComment';
import {
  Button,
  ButtonAppearance,
  ButtonColor,
  ButtonShape
} from '@tablecheck/tablekit-button';
import { Icon } from '@tablecheck/tablekit-icon';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { GoBack } from 'components/TopNav/HelpCenter/elements/GoBack';
import { HelpCenterLink } from 'components/TopNav/HelpCenter/elements/HelpCenterLink';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import {
  ActionsWrapper,
  Header,
  Title
} from 'components/TopNav/HelpCenter/styled';

export function PanelHeader({
  closePanel
}: {
  closePanel: () => void;
}): JSX.Element {
  const { helpScreen } = React.useContext(HelpCenterContext);
  const { search } = useLocation();
  const history = useHistory();
  const [t] = useTranslation();
  const openContactUsForm = () => history.replace(`${search}&contactForm=true`);

  return (
    <Header>
      <GoBack />
      <Title>{t('portal:navigation.help')}</Title>
      <ActionsWrapper>
        <HelpCenterLink />
        {[HelpCenterScreen.ContactUsForm, HelpCenterScreen.MessageSent].indexOf(
          helpScreen
        ) === -1 ? (
          <Button
            appearance={ButtonAppearance.Subtle}
            shape={ButtonShape.Circular}
            iconBefore={<Icon icon={faComment} />}
            onClick={openContactUsForm}
          />
        ) : null}
        <Button
          appearance={ButtonAppearance.Subtle}
          shape={ButtonShape.Circular}
          color={ButtonColor.Ghost}
          iconBefore={<Icon icon={faTimes} />}
          onClick={closePanel}
        />
      </ActionsWrapper>
    </Header>
  );
}
