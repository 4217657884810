import styled from '@emotion/styled';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AppNotice } from 'components/AppNoticesModal/AppNotice';
import { AppNoticesWrapper } from 'components/AppNoticesModal/styles';
import { usePortalAppNotices } from 'components/AppNoticesModal/usePortalAppNotices';
import { Breakpoint } from 'components/TopNav/constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-l5) 0;

  & > div {
    max-width: 600px;
    background-color: var(--surface);
    padding: var(--spacing-l4);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-large);

    @media (max-width: ${Breakpoint.Mobile}) {
      max-width: unset;
    }
  }
`;

export function AppNotices(): JSX.Element | null {
  const [t] = useTranslation();
  const appNotices = usePortalAppNotices();

  if (!appNotices.length) {
    return null;
  }

  return (
    <Container>
      <AppNoticesWrapper>
        {appNotices.map((appNotice) => (
          <AppNotice key={appNotice.id} appNotice={appNotice} t={t} />
        ))}
      </AppNoticesWrapper>
    </Container>
  );
}
