import { getAppPath } from 'exports';
import { AppName } from 'utils/constants';

import { SspaApp } from './base';

export const settingsApp = new SspaApp({
  name: AppName.Settings,
  label: `portal:navigation.apps.${AppName.Settings}`,
  path: getAppPath('settings'),
  manifestHost: CONFIG.spaConfig.settings
});
