export const APP_THEMES = ['classic', 'light', 'dark'] as const;
export type AppTheme = (typeof APP_THEMES)[number];
export const themeLocalStorageKey = 'tablecheck-merchant-app-theme';

export interface AppThemeFunction {
  /**
   * Called to change/set the current theme
   * @param theme
   * @param isPreview - if set we are previewing the theme and the change should not be persisted
   */
  (theme: AppTheme, isPreview?: boolean): void;
}

declare global {
  interface Window {
    /**
     * Use this to register listeners to the application theme
     * @param listener
     */
    registerAppThemeListener(listener: AppThemeFunction): VoidFunction;
  }
}
