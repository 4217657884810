import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import * as Dialog from '@radix-ui/react-dialog';

export const overlayShow = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const contentShow = keyframes`
  0% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  background-color: rgba(0, 0, 0, 0.55);
  position: fixed;
  inset: 0;
  animation: ${overlayShow};
  animation-duration: 0.4s;
  z-index: var(--zindex-blanket);
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  background-color: var(--surface);
  border-radius: var(--border-radius-small);
  position: fixed;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  padding: var(--spacing-l5);
  animation: ${contentShow};
  animation-duration: 0.4s;
  z-index: var(--zindex-modal);
  max-width: 600px;
  min-width: 400px;
  max-height: 80vh;
`;

export const AppNoticesContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: var(--spacing-l1);
  background-color: var(--surface);
  overflow: hidden;
`;

export const AppNoticesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
`;

export const AppNoticeBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--spacing-l4);
  padding: var(--spacing-l1);
  background-color: var(--surface);

  ul,
  ol {
    list-style: revert;
    padding: 0 1rem;
  }

  p:empty {
    height: 1em;
  }

  &:not(:last-child) {
    margin-bottom: var(--spacing-l6);

    &:after {
      content: '';
      display: block;
      background-color: var(--border);
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: calc(var(--spacing-l6) / 2 * -1);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l4);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l2);
  justify-content: space-between;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-l2);
`;

export const DateWrapper = styled.span`
  font: var(--small);
`;
