import { Authenticator, setConfig } from '@tablecheck/react-sso-authenticator';
import { Alert, AlertAppearance } from '@tablecheck/tablekit-alert';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from 'components/ErrorBoundary';
import { i18n } from 'i18n';
import { ErrorWrapper } from 'scenes/styled';

import { MainLoader } from '../assets/img/loading';

import { InternalThemeProvider } from './ThemeProvider';

setConfig({
  '@tablecheck-sso/access-token-uri':
    CONFIG['@tablecheck-sso/access-token-uri'],
  '@tablecheck-sso/authorization-uri':
    CONFIG['@tablecheck-sso/authorization-uri'],
  '@tablecheck-sso/client-id': CONFIG['@tablecheck-sso/client-id'],
  '@tablecheck-sso/client-secret': CONFIG['@tablecheck-sso/client-secret'],
  '@tablecheck-sso/logout-uri': CONFIG['@tablecheck-sso/logout-uri'],
  '@tablecheck-sso/method': CONFIG['@tablecheck-sso/method'] as
    | 'code'
    | 'token',
  publicUri: CONFIG.publicUri
});

export function AppWrapper({
  renderContent
}: {
  renderContent: () => JSX.Element;
}): JSX.Element | null {
  return (
    <I18nextProvider i18n={i18n}>
      <InternalThemeProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <Authenticator
              renderApp={({ state, error }) => {
                if (state === 'error' && (error as unknown) instanceof Error) {
                  // This catches the case where the user gets redirected back to `/auth-redirect` with a bad state
                  // or the url is somehow bad
                  window.location.replace('/');
                } else if (state === 'error') {
                  return (
                    <ErrorWrapper>
                      <Alert appearance={AlertAppearance.Warning} isShow>
                        <span>{error || 'UNKNOWN ERROR'}</span>
                      </Alert>
                    </ErrorWrapper>
                  );
                }
                if (state === 'authenticated') return renderContent();
                return <MainLoader data-testid="Main Loader" />;
              }}
            />
          </BrowserRouter>
        </ErrorBoundary>
      </InternalThemeProvider>
    </I18nextProvider>
  );
}
