export const HELP_SECTION_BUTTON_ID = 'help-section-button';

export enum TopNavButtonAppearance {
  Settings = 'settings',
  Notifications = 'notifications',
  User = 'user'
}

export enum Breakpoint {
  Mobile = '853px',
  Desktop = '1280px'
}

export const MENU_PORTAL_Z_INDEX = Number.MAX_SAFE_INTEGER;
