import { CaretRight } from '@carbon/icons-react';
import * as React from 'react';
import { TFunction } from 'react-i18next';

import { AppNoticeTextSectionAPI } from 'components/AppNoticesModal/types';

import {
  Details,
  DetailsMessage,
  Label,
  Summary,
  TextWrapper
} from './Text.styles';
import { Title, Message } from './styles';

export function Text({
  element,
  messagePlaceholder = '',
  t
}: {
  element: AppNoticeTextSectionAPI;
  messagePlaceholder?: string;
  t: TFunction;
}): JSX.Element {
  const {
    title_text: title,
    content_text: message,
    content_layout: textType
  } = element;

  if (textType === 'accordion') {
    return (
      <TextWrapper data-type="framed">
        <Details>
          <Summary>
            <Label>
              <CaretRight size={20} />
              <span>{t('portal:app_notice.actions.show_more')}</span>
            </Label>
          </Summary>
          {title ? <Title>{title}</Title> : null}
          <DetailsMessage data-text-placeholder={!message}>
            <div
              dangerouslySetInnerHTML={{
                __html: message || messagePlaceholder
              }}
            />
          </DetailsMessage>
        </Details>
      </TextWrapper>
    );
  }

  return (
    <TextWrapper data-type={textType}>
      {title ? <Title>{title}</Title> : null}
      <Message data-text-placeholder={!message}>
        <div
          dangerouslySetInnerHTML={{
            __html: message || messagePlaceholder
          }}
        />
      </Message>
    </TextWrapper>
  );
}
