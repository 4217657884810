/* eslint-disable @tablecheck/max-mixed-css */
import styled from '@emotion/styled';

export const SvgElement = styled.svg`
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 50%;

  #es3gfcuj4ix8_to {
    animation: es3gfcuj4ix8_to__to 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix8_to__to {
    0% {
      transform: translate(308.45px, 314.13px);
    }
    58.333333% {
      transform: translate(308.45px, 314.13px);
    }
    75% {
      transform: translate(308.443761px, 300.065px);
    }
    100% {
      transform: translate(308.443761px, 339.8px);
    }
  }
  #es3gfcuj4ix8_ts {
    animation: es3gfcuj4ix8_ts__ts 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix8_ts__ts {
    0% {
      transform: scale(0, 0);
    }
    25% {
      transform: scale(1.2, 1.2);
      animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    }
    41.666667% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  #es3gfcuj4ix8 {
    animation: es3gfcuj4ix8_c_o 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix8_c_o {
    0% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  #es3gfcuj4ix9_to {
    animation: es3gfcuj4ix9_to__to 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix9_to__to {
    0% {
      transform: translate(308.45px, 314.13px);
    }
    58.333333% {
      transform: translate(308.45px, 314.13px);
    }
    75% {
      transform: translate(308.45px, 302px);
    }
    100% {
      transform: translate(308.45px, 339px);
    }
  }
  #es3gfcuj4ix9_tr {
    animation: es3gfcuj4ix9_tr__tr 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix9_tr__tr {
    0% {
      transform: rotate(30deg);
    }
    25% {
      transform: rotate(30deg);
    }
    41.666667% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  #es3gfcuj4ix9 {
    animation: es3gfcuj4ix9_c_o 1200ms linear infinite normal forwards;
  }
  @keyframes es3gfcuj4ix9_c_o {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    41.666667% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export function MainLoader(): JSX.Element {
  return (
    <SvgElement
      id="es3gfcuj4ix1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
    >
      <circle
        id="es3gfcuj4ix2"
        r="217.590000"
        transform="matrix(1 0 0 1 250 250)"
        fill="rgb(232,228,242)"
        stroke="rgb(255,255,255)"
        strokeWidth="1"
        strokeMiterlimit="10"
      />
      <path
        id="es3gfcuj4ix3"
        d="M341.360000,348.960000L158.640000,348.960000C153.460000,348.960000,149.260000,344.760000,149.260000,339.580000L149.260000,156.870000C149.260000,151.690000,153.460000,147.490000,158.640000,147.490000L341.350000,147.490000C346.530000,147.490000,350.730000,151.690000,350.730000,156.870000L350.730000,339.580000C350.740000,344.770000,346.540000,348.960000,341.360000,348.960000Z"
        fill="rgb(255,255,255)"
        stroke="none"
        strokeWidth="1"
      />
      <g id="es3gfcuj4ix4">
        <path
          id="es3gfcuj4ix5"
          d="M250,158.950000C200.690000,158.950000,160.720000,198.920000,160.720000,248.230000C160.720000,275.190000,172.670000,299.350000,191.550000,315.720000C207.210000,329.290000,227.640000,337.510000,250,337.510000C272.360000,337.510000,292.790000,329.300000,308.450000,315.720000C327.340000,299.350000,339.280000,275.180000,339.280000,248.230000C339.280000,198.920000,299.310000,158.950000,250,158.950000Z"
          fill="rgb(182,236,237)"
          stroke="none"
          strokeWidth="1"
        />
        <path
          id="es3gfcuj4ix6"
          d="M308.450000,315.720000C296.660000,295.720000,274.900000,282.290000,250,282.290000C225.100000,282.290000,203.350000,295.720000,191.550000,315.720000C207.210000,329.290000,227.640000,337.510000,250,337.510000C272.360000,337.510000,292.790000,329.290000,308.450000,315.720000Z"
          fill="rgb(81,146,252)"
          stroke="none"
          strokeWidth="1"
        />
        <circle
          id="es3gfcuj4ix7"
          r="42.280000"
          transform="matrix(1 0 0 1 250 235.31999999999999)"
          fill="rgb(81,146,252)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g id="es3gfcuj4ix8_to" transform="translate(308.450000,314.130000)">
        <g id="es3gfcuj4ix8_ts" transform="scale(0,0)">
          <circle
            id="es3gfcuj4ix8"
            r="34"
            transform="translate(-1.940000,-2.290000)"
            fill="rgb(85,185,55)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="es3gfcuj4ix9_to" transform="translate(308.450000,314.130000)">
        <g id="es3gfcuj4ix9_tr" transform="rotate(30)">
          <path
            id="es3gfcuj4ix9"
            d="M327.800000,304.610000L302.570000,329.840000C301.710000,330.700000,300.320000,330.700000,299.470000,329.840000L285.210000,315.580000C284.350000,314.720000,284.350000,313.330000,285.210000,312.480000L288.310000,309.380000C289.170000,308.520000,290.560000,308.520000,291.410000,309.380000L301.010000,318.990000L321.580000,298.420000C322.440000,297.560000,323.830000,297.560000,324.680000,298.420000L327.780000,301.520000C328.660000,302.370000,328.660000,303.760000,327.800000,304.610000Z"
            transform="translate(-308.450000,-314.130000)"
            opacity="0"
            fill="rgb(255,255,255)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
    </SvgElement>
  );
}
/* eslint-enable @tablecheck/max-mixed-css */
