import { Theme } from '@emotion/react';
import type {
  alertClassicTheme,
  alertThemeNamespace
} from '@tablecheck/tablekit-alert';
import type {
  buttonClassicTheme,
  buttonThemeNamespace
} from '@tablecheck/tablekit-button';
import type {
  inputClassicTheme,
  inputThemeNamespace
} from '@tablecheck/tablekit-input';
import type {
  itemClassicTheme,
  itemThemeNamespace
} from '@tablecheck/tablekit-item';
import type {
  panelClassicTheme,
  panelThemeNamespace
} from '@tablecheck/tablekit-panel';
import type {
  spinnerClassicTheme,
  spinnerThemeNamespace
} from '@tablecheck/tablekit-spinner';
import type {
  topnavClassicTheme,
  topnavThemeNamespace
} from '@tablecheck/tablekit-topnav';

export const portalThemeNamespace = '@tablecheck/portal' as const;

export type PortalSpaceTheme = {
  colors: {
    bgColor: string;
    textColor: string;
    textSubtle: string;
    cardBg: string;
    navbarBackground: string;
    navbarForeground: string;
    navbarBackgroundSelected: string;
    navbarForegroundSelected: string;
    navbarBackgroundHovered: string;
    navbarForegroundHovered: string;
    navbarSelectedBorder: string;
  };
};

export type AppTheme = Pick<Theme, 'colors' | 'isDark'> & {
  [alertThemeNamespace]: typeof alertClassicTheme;
  [buttonThemeNamespace]: typeof buttonClassicTheme;
  [inputThemeNamespace]: typeof inputClassicTheme;
  [itemThemeNamespace]: typeof itemClassicTheme;
  [panelThemeNamespace]: typeof panelClassicTheme;
  [spinnerThemeNamespace]: typeof spinnerClassicTheme;
  [topnavThemeNamespace]: typeof topnavClassicTheme;
  [portalThemeNamespace]: PortalSpaceTheme;
};
