import { Alert, AlertAppearance } from '@tablecheck/tablekit-alert';
import { i18n as I18next } from 'i18next';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Transition, TransitionGroup } from 'react-transition-group';
import {
  addErrorHandler,
  AppError,
  getAppNames,
  getAppStatus,
  registerApplication,
  removeErrorHandler,
  start,
  triggerAppChange,
  unloadApplication
} from 'single-spa';

import { apps } from 'apps';
import { RegisterApp, useAppRegistry } from 'apps/Registry';
import { SspaAppProps } from 'apps/types';
import { MainLoader } from 'assets/img/loading';
import { TopNav } from 'components/TopNav';
import { AppSwitcher } from 'components/TopNav/AppSwitcher';
import { registerMenuItems } from 'components/TopNav/SettingsDialog/exports';
import { useLocalePersistance } from 'i18n';
import { AppContext, appReducer } from 'scenes/Routes/AppContext';
import {
  AppContainer,
  ErrorWrapper,
  Fade,
  FixedFade,
  TRANSITION_PROPS
} from 'scenes/styled';
import { MerchantUser } from 'types/MerchantUser';
import { useAppThemePersistance } from 'utils/appTheme/internal';
import { SSPA_DIV_ID } from 'utils/constants';
import { CurrentUserResponse } from 'utils/currentUser';
import { registerOnAuthChangeListener } from 'utils/registerOnAuthChangeListener';
import { configureSentryScope, reportSentryError } from 'utils/sentry';

import { AppContext as AppContextType, AppStateEvents } from '../types';

import { loadUser } from './loadUser';

function registerApps({
  i18n,
  sendAppEvent,
  registerApp,
  currentUser
}: {
  i18n: I18next;
  sendAppEvent: (event: AppStateEvents) => void;
  registerApp: RegisterApp;
  currentUser: CurrentUserResponse;
}) {
  apps.forEach((app) => {
    const { name, matchFunction, manifestPromise, manifestHost } = app;
    if (manifestHost) {
      manifestPromise
        .then(({ appVersion }) => {
          if (appVersion) {
            registerApp({ name, version: appVersion });
          } else {
            registerApp({ name, version: '-' });
          }
        })
        .catch(() => {
          registerApp({ name, version: 'load error' });
        });
    }
    registerApplication<SspaAppProps>(
      name,
      () => {
        sendAppEvent({ type: 'LOAD_APP' });
        return app.loadingFunction().then((result) => {
          sendAppEvent({ type: 'APP_LOADED' });
          return result;
        });
        // this catch is handled by the errorHandler
      },
      matchFunction, // These are the props that get passed to each SPA render instance
      // See dashboardApp for an example
      {
        onAuthChange: registerOnAuthChangeListener,
        baseRouterPath: app.path,
        getInitialLocale: () => i18n.language,
        changeLocale: (newLocale) => i18n.changeLanguage(newLocale),
        registerMenuItems,
        currentUser
      }
    );
  });
}

function init(
  i18n: I18next,
  sendAppEvent: (event: AppStateEvents) => void,
  registerApp: RegisterApp
) {
  sendAppEvent({ type: 'INIT' });
  loadUser(i18n)
    .then((currentUser) => {
      registerApps({
        i18n,
        sendAppEvent,
        registerApp,
        currentUser
      });

      sendAppEvent({
        type: 'USER_LOADED',
        merchantUser: currentUser.current_user
      });
      configureSentryScope((scope) => {
        scope.setUser({
          id: currentUser.current_user.id
        });
      });
      start();
    })
    .catch((error) => {
      console.error(error);
      sendAppEvent({ type: 'AUTH_ERROR', error });
      reportSentryError(error, {
        extra: {
          info: 'Authorization failed'
        }
      });
    });
}

export function AppRouter(): JSX.Element {
  useAppThemePersistance(reportSentryError);
  useLocalePersistance(reportSentryError);
  const [, registerApp] = useAppRegistry();
  const { i18n } = useTranslation();
  const [context, sendAppEvent] = React.useReducer(
    appReducer,
    undefined,
    () => ({ state: 'init' } as AppContextType)
  );

  React.useEffect(() => {
    const beforeRouteChange = () => {
      if (window.portalNavbarCenterRef) {
        delete window.portalNavbarCenterRef.dataset.variant;
      }
      sendAppEvent({ type: 'LOADING' });
    };
    window.addEventListener(
      'single-spa:before-routing-event',
      beforeRouteChange
    );
    return () =>
      window.removeEventListener(
        'single-spa:before-routing-event',
        beforeRouteChange
      );
  }, []);

  React.useEffect(() => {
    function errorHandler(error: AppError) {
      console.error(error);
      sendAppEvent({ type: 'ERROR', error });
      reportSentryError(error);
    }

    addErrorHandler(errorHandler);
    return () => removeErrorHandler(errorHandler);
  }, [sendAppEvent]);

  React.useEffect(() => {
    init(i18n, sendAppEvent, registerApp);
  }, [i18n]);

  const contextValue: {
    context: AppContextType;
    actions: { updateUser: (user: MerchantUser) => void };
  } = React.useMemo(
    () => ({
      context,
      actions: {
        updateUser: (merchantUser) => {
          sendAppEvent({ type: 'USER_UPDATED', merchantUser });
        }
      }
    }),
    [context]
  );

  return (
    <AppContext.Provider value={contextValue}>
      {context.merchantUser && (
        <TopNav user={context.merchantUser} appSwitcher={<AppSwitcher />} />
      )}
      <Transition {...TRANSITION_PROPS} in={context.state === 'ready'}>
        {(state) => (
          <Fade data-state={state}>
            <AppContainer id={SSPA_DIV_ID}>SPA CONTENT</AppContainer>
          </Fade>
        )}
      </Transition>
      <TransitionGroup>
        {context.state === 'error' && (
          <Transition {...TRANSITION_PROPS}>
            {(state) => (
              <FixedFade data-state={state}>
                <ErrorWrapper>
                  <Alert
                    appearance={AlertAppearance.Warning}
                    isShow
                    actions={[
                      {
                        onClick: () => {
                          if (context.isAuthError) {
                            init(i18n, sendAppEvent, registerApp);
                            return;
                          }
                          sendAppEvent({ type: 'LOAD_APP' });
                          const allAppNames = getAppNames();
                          Promise.all(
                            allAppNames.map((appName) => {
                              const appStatus = getAppStatus(appName);
                              if (appStatus !== 'LOAD_ERROR')
                                return Promise.resolve();
                              return unloadApplication(appName);
                            })
                          ).then(() => triggerAppChange());
                        },
                        text: i18n.t('actions:retry')
                      }
                    ]}
                  >
                    {i18n.t('portal:messages.maintenance')}
                  </Alert>
                </ErrorWrapper>
              </FixedFade>
            )}
          </Transition>
        )}
        {(context.state === 'loading' ||
          context.state === 'app_transition') && (
          <Transition {...TRANSITION_PROPS}>
            {(state) => (
              <FixedFade data-state={state}>
                <MainLoader data-testid="Main Loader" />
              </FixedFade>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </AppContext.Provider>
  );
}
