import styled from '@emotion/styled';
import * as DropdownMenuImport from '@radix-ui/react-dropdown-menu';
import { DEPTH } from '@tablecheck/tablekit-theme';

export const DropdownMenu = {
  ...DropdownMenuImport,
  Content: styled(DropdownMenuImport.Content)`
    display: block;
    position: relative;
    background: ${({ theme }) => theme.colors.surface};
    ${DEPTH.OVERLAY}
  `
};
