import { UserFilled } from '@carbon/icons-react';
import styled from '@emotion/styled';
import * as React from 'react';

const AvatarWrapper = styled.div<{
  'data-size': 'small' | 'large';
}>`
  --size: 28px;
  --internal-size: 14px;
  --border-radius: 4px;
  &[data-size='large'] {
    --size: 128px;
    --internal-size: 36px;
    --border-radius: 8px;
  }
  width: var(--size);
  height: var(--size);
  font-size: var(--internal-size);
  border-radius: var(--border-radius);

  svg {
    width: var(--internal-size);
    height: var(--internal-size);
  }

  background-color: ${({ theme }) => theme.colors.primary2};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
`;

export function Avatar({
  name,
  initials,
  imageUrl,
  size,
  ...restProps
}: {
  name: string;
  initials: string;
  imageUrl: string;
  size: 'small' | 'large';
}): JSX.Element {
  let content: JSX.Element | string = <UserFilled size={20} />;
  if (initials) {
    content = initials.toUpperCase();
  }
  if (imageUrl) {
    content = <img src={imageUrl} alt={name} title={name} />;
  }

  return (
    <AvatarWrapper data-size={size} {...restProps}>
      {content}
    </AvatarWrapper>
  );
}
