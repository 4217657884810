import { getAxios } from '@tablecheck/react-sso-authenticator';
import axios from 'axios';

import { MerchantUser } from 'types/MerchantUser';
import { reportSentryError } from 'utils/sentry';

export const buildFormData = (
  object: Record<string, string | Blob>
): FormData => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

export const updateUserAvatar = async (
  imageUploadKey: string
): Promise<MerchantUser> =>
  getAxios()
    .put(`${CONFIG.apiPath}current_user`, {
      avatar_upload_key: imageUploadKey
    })
    .then(({ data }) => data.current_user)
    .catch((error) => {
      console.error(error);
      reportSentryError(error, {
        extra: {
          info: 'Failed to update user avatar'
        }
      });
    });

export const removeUserAvatar = async (): Promise<MerchantUser> =>
  getAxios()
    .put(`${CONFIG.apiPath}current_user`, {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _destroy_avatar: true
    })
    .then(({ data }) => data.current_user)
    .catch((error) => {
      console.error(error);
      reportSentryError(error, {
        extra: {
          info: 'Failed to remove user avatar'
        }
      });
    });

export const uploadImage = async (file: File): Promise<MerchantUser> => {
  const {
    data: {
      form: { fields, url }
    }
  } = await getAxios().post(`${CONFIG.apiPath}presigned_uploads`);
  const s3Url = `https://${url}`;
  const s3Key = (fields.key || '').replace(
    // eslint-disable-next-line no-template-curly-in-string
    '${filename}',
    file.name
  );
  const newFields = { ...fields, 'Content-Type': file.type, key: s3Key };
  delete newFields.success_action_status;
  const formData = buildFormData(newFields);
  formData.append('file', file);
  await axios.post(s3Url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return updateUserAvatar(s3Key);
};
