import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import * as React from 'react';
import ReactDOM from 'react-dom';
import '@tablecheck/tablekit-pro-icon-config';

import { App } from 'App';
import 'utils/appLink';
import { initSentry } from 'utils/sentry';

const isolatedCache = createCache({
  key: 'portal'
});

initSentry();

if (
  CONFIG.spaConfig.insight.search('staging') === -1 &&
  CONFIG.spaConfig.insight.search('pilot') === -1
) {
  const GA_ID = 'UA-151908461-1';
  if (!document.querySelector('script#load-gtag')) {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute('id', 'load-gtag');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`
    );
    if (document.documentElement && document.documentElement.firstChild) {
      document.documentElement.firstChild.appendChild(gaScript);
    }
  }

  const gaScript2 = document.createElement('script');
  gaScript2.innerText = `if (!window.dataLayer) { window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GA_ID}', { send_page_view: false }); }`;

  if (document.documentElement && document.documentElement.firstChild) {
    document.documentElement.firstChild.appendChild(gaScript2);
  }
}

let rootElement = document.getElementById('root');
if (!rootElement) {
  rootElement = document.createElement('div');
  document.body.appendChild(rootElement);
}

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <CacheProvider value={isolatedCache}>
      <App />
    </CacheProvider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <CacheProvider value={isolatedCache}>
      <App />
    </CacheProvider>,
    rootElement
  );
}
