import { Wikis } from '@carbon/icons-react';
import { css } from '@emotion/react';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle';
import { ordered } from '@tablecheck/locales';
import { Icon } from '@tablecheck/tablekit-icon';
import { LanguageSelector, View } from '@tablecheck/tablekit-language-selector';
import { Size } from '@tablecheck/tablekit-theme';
import queryString from 'query-string';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useMedia from 'react-use/esm/useMedia';

import { AppNoticesItem } from 'components/TopNav/AppNoticesItem';
import { ZendeskPanel } from 'components/TopNav/HelpCenter';
import { useImagePreview } from 'components/TopNav/UserPanel/useImagePreview';
import { MerchantUser } from 'types/MerchantUser';
import { getAppPath } from 'utils/appLink';
import { useAppTheme } from 'utils/appTheme/exports';
import { AppName, CATEGORY_ID, RoutePath } from 'utils/constants';

import { HelpItem } from './HelpItem';
import { SettingsDialog } from './SettingsDialog';
import { UserPanelContent } from './UserPanel';
import { Breakpoint, TopNavButtonAppearance } from './constants';
import {
  CenterContainer,
  ItemGroupWrapper,
  LangWrapper,
  LeftContainer,
  NavItemButton,
  PositionedTopNav,
  RightContainer,
  SelectedLang,
  UserPanel,
  TopNavAvatar
} from './styled';

type PropsType = {
  user: MerchantUser;
  appSwitcher: JSX.Element;
  hideAppNoticesTopNavItem?: boolean;
};

export function TopNav({
  user,
  hideAppNoticesTopNavItem,
  appSwitcher
}: PropsType): JSX.Element {
  const appTheme = useAppTheme();
  const [selectedButton, setButtonActive] =
    React.useState<void | TopNavButtonAppearance>();
  const [avatarFile, setAvatarFile] = React.useState<File | undefined>();
  const [, { language, changeLanguage }] = useTranslation();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { view } = queryString.parse(search);
  const isMobile = useMedia(`(max-width: ${Breakpoint.Mobile})`);
  const imagePreview = useImagePreview({
    imageFile: avatarFile,
    imageUrls: user.avatar_urls,
    size: 'xs'
  });

  const toggleWidget = React.useCallback(
    (isOpen: boolean = !view || view !== 'help') => {
      if (isOpen) {
        if (pathname.startsWith(getAppPath('insight'))) {
          history.replace({
            search: `?view=help&categoryId=${CATEGORY_ID[AppName.Insight]}`
          });
        } else {
          history.replace({
            search: '?view=help'
          });
        }
      } else {
        history.replace(pathname);
      }
    },
    [history, view, pathname]
  );
  window.togglePortalHelpPanel = toggleWidget;

  return (
    <>
      <PositionedTopNav data-theme={appTheme}>
        <LeftContainer>
          <ItemGroupWrapper>{appSwitcher}</ItemGroupWrapper>
        </LeftContainer>
        <CenterContainer
          id="top-nav-center" // for legacy compatability, prefer to use the ref as it's typescript compatible
          ref={(ref) => {
            window.portalNavbarCenterRef = ref;
          }}
        />
        <RightContainer>
          <ItemGroupWrapper>
            {hideAppNoticesTopNavItem ? null : (
              <AppNoticesItem userId={user.id} />
            )}
            <LanguageSelector
              containerCss={css`
                @media (max-width: ${Breakpoint.Mobile}) {
                  width: 100%;
                }
              `}
              view={isMobile ? View.Mobile : undefined}
              shouldShowCloseIcon
              currentLanguage={language}
              itemWidth={isMobile ? '100%' : undefined}
              locales={ordered}
              renderTrigger={({ onClick, isOpen, ref }) => (
                <NavItemButton
                  data-selected={isOpen}
                  width="fit-content"
                  onClick={onClick}
                  ref={ref}
                >
                  <LangWrapper>
                    {isMobile ? null : (
                      <SelectedLang>
                        {language.slice(-2).toUpperCase()}
                      </SelectedLang>
                    )}
                    <Wikis size={20} />
                  </LangWrapper>
                </NavItemButton>
              )}
              onChangeLanguage={changeLanguage}
            />
            <HelpItem
              isSelected={view === RoutePath.Help}
              toggleWidget={toggleWidget}
            />
            <NavItemButton
              id="user-panel-button"
              onClick={() => {
                setButtonActive(
                  selectedButton === TopNavButtonAppearance.User
                    ? undefined
                    : TopNavButtonAppearance.User
                );
              }}
              width="fit-content"
              data-selected={selectedButton === TopNavButtonAppearance.User}
            >
              {user ? (
                <TopNavAvatar
                  name={user.name}
                  initials={user.initials}
                  imageUrl={imagePreview}
                  size="small"
                />
              ) : (
                <Icon icon={faUserCircle} size={Size.XLarge} />
              )}
            </NavItemButton>
            <SettingsDialog />
          </ItemGroupWrapper>
        </RightContainer>
      </PositionedTopNav>
      <UserPanel
        isOpen={selectedButton === TopNavButtonAppearance.User}
        togglePanel={() => {
          setButtonActive(undefined);
        }}
        onClickOutside={(event, togglePanel) => {
          const triggerButton = document.querySelector('#user-panel-button');
          if (
            triggerButton &&
            togglePanel &&
            event.currentTarget &&
            !triggerButton.contains(event.target as Node)
          ) {
            togglePanel();
          }
        }}
        width="360px"
      >
        <UserPanelContent
          user={user}
          avatarFile={avatarFile}
          setAvatarFile={setAvatarFile}
        />
      </UserPanel>
      <ZendeskPanel user={user} toggleWidget={toggleWidget} />
    </>
  );
}
