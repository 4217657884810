import * as React from 'react';

interface AppRegister {
  name: string;
  version: string;
}

export interface RegisterApp {
  (newApp: AppRegister): void;
}

type AppRegistryContext = [AppRegister[], RegisterApp];

const Context = React.createContext<AppRegistryContext>([[], () => {}]);

export function AppRegistry({
  children
}: React.PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const providerValue = React.useReducer(
    (currentState: AppRegister[], newApp: AppRegister) => {
      const currentIndex = currentState.findIndex(
        (app) => app.name === newApp.name
      );
      if (currentIndex === -1) return currentState.concat([newApp]);
      return currentState
        .slice(0, currentIndex)
        .concat([newApp], currentState.slice(currentIndex + 1));
    },
    []
  );
  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
}

export function useAppRegistry(): AppRegistryContext {
  return React.useContext(Context);
}
