import { AppRouter } from 'scenes/Routes';

import { AppRegistry } from '../apps/Registry';

import { AppWrapper } from './Wrapper';

export function App(): JSX.Element {
  return (
    <AppWrapper
      renderContent={() => (
        <AppRegistry>
          <AppRouter />
        </AppRegistry>
      )}
    />
  );
}
