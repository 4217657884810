import { ResizablePanelProps } from '@tablecheck/tablekit-panel';
import queryString from 'query-string';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { HelpCenterContextProvider } from 'components/TopNav/HelpCenter/HelpCenterContext/HelpCenterContextProvider';
import { HelpRouter } from 'components/TopNav/HelpCenter/HelpRouter';
import { Breadcrumbs } from 'components/TopNav/HelpCenter/elements/Breadcrumbs';
import { PanelHeader } from 'components/TopNav/HelpCenter/elements/PanelHeader';
import { HelpCenterSearch } from 'components/TopNav/HelpCenter/elements/SearchBox';
import { ZendeskPanelContent } from 'components/TopNav/HelpCenter/styled';
import { HELP_SECTION_BUTTON_ID } from 'components/TopNav/constants';
import { ResizablePanelContainer } from 'components/TopNav/styled';
import { MerchantUser } from 'types/MerchantUser';
import { isInsightSettingsPage } from 'utils/index';

export function ZendeskPanel({
  user,
  toggleWidget
}: {
  toggleWidget: typeof window.togglePortalHelpPanel;
  user: MerchantUser;
}): JSX.Element {
  const { search, pathname } = useLocation();
  const { view } = queryString.parse(search);

  const onClickOutside = React.useCallback<
    Required<ResizablePanelProps>['onClickOutside']
  >(
    (event) => {
      const helpCenterLink = document.querySelector(
        `#${HELP_SECTION_BUTTON_ID}`
      );
      const isBlockClickOutside =
        isInsightSettingsPage(pathname) ||
        helpCenterLink?.contains(event.target as Node);

      if (!isBlockClickOutside) {
        toggleWidget();
      }
    },
    [pathname, toggleWidget]
  );
  return (
    <ResizablePanelContainer
      togglePanel={toggleWidget}
      isOpen={view === 'help'}
      defaultWidth={475}
      minWidth={360}
      maxWidth={1000}
      onClickOutside={onClickOutside}
    >
      <HelpCenterContextProvider user={user}>
        <ZendeskPanelContent>
          <PanelHeader closePanel={() => toggleWidget(false)} />
          <HelpCenterSearch />
          <Breadcrumbs />
          <HelpRouter />
        </ZendeskPanelContent>
      </HelpCenterContextProvider>
    </ResizablePanelContainer>
  );
}
