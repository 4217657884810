import * as React from 'react';

import { APP_THEMES, AppTheme, themeLocalStorageKey } from './constants';

export type { APP_THEMES, AppTheme } from './constants';

export function getCurrentAppTheme(): AppTheme {
  const maybeAppTheme =
    window.localStorage.getItem(themeLocalStorageKey) || 'classic';
  if (!APP_THEMES.includes(maybeAppTheme as AppTheme)) return 'classic';
  return maybeAppTheme as AppTheme;
}

export function useAppTheme(shouldIgnorePreview?: boolean): AppTheme {
  const [appTheme, setTheme] = React.useState(() => getCurrentAppTheme());
  React.useEffect(
    () =>
      window.registerAppThemeListener((theme, isPreview) => {
        if (isPreview && shouldIgnorePreview) return;
        setTheme(theme);
      }),
    [setTheme, shouldIgnorePreview]
  );
  return appTheme;
}
