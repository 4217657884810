import { Link as TablekitStyledLink } from '@tablecheck/tablekit-typography';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import {
  BreadcrumbCurrent,
  BreadcrumbsContainer
} from 'components/TopNav/HelpCenter/styled';

const TablekitLink = TablekitStyledLink.withComponent(Link);

export function Breadcrumbs(): JSX.Element {
  const [t] = useTranslation();
  const {
    categoryId,
    categories,
    sectionId,
    article,
    sections,
    search,
    helpScreen,
    isContactForm,
    isMessageSent
  } = React.useContext(HelpCenterContext);
  const section = sections.find(({ id }) =>
    article ? id === article.section_id : id === sectionId
  );
  const category = categories.find(({ id }) =>
    section ? id === section.category_id : id.toString() === categoryId
  );
  const crumbs: {
    href: string;
    title: string;
  }[] = [
    {
      href: '?view=help',
      title: t('portal:help.help_center')
    }
  ];
  if (category && !isContactForm) {
    crumbs.push({
      href: `?view=help&categoryId=${category.id}`,
      title: category.name
    });
  }
  if (section && !isContactForm) {
    crumbs.push({
      href: `?view=help&categoryId=${section.category_id}&sectionId=${section.id}`,
      title: section.name
    });
  }
  if (search && !article && !isContactForm) {
    crumbs.push({
      href: `?view=help&search=${search}`,
      title: t('portal:help.help_center_search_results')
    });
  }
  if (article && !isContactForm) {
    crumbs.push({
      href: `?view=help&sectionId=${article.section_id}&articleId=${article.id}`,
      title: article.name
    });
  }
  if (isContactForm) {
    crumbs.push({
      href: '?view=help&contactForm=true',
      title: t('portal:help.help_center_contact_us')
    });
  }
  if (isMessageSent) {
    crumbs.push({
      href: '?view=help&messageSent=true',
      title: t('portal:help.help_center_message_sent')
    });
  }
  return (
    <BreadcrumbsContainer>
      {crumbs.map<React.ReactNode>((crumb, index) => {
        if (
          (crumbs.length > 1 ||
            helpScreen === HelpCenterScreen.CategoriesList) &&
          index === crumbs.length - 1
        ) {
          return (
            <BreadcrumbCurrent key={crumb.href}>
              {crumb.title}
            </BreadcrumbCurrent>
          );
        }

        return (
          <TablekitLink key={crumb.href} to={crumb.href}>
            {crumb.title}
          </TablekitLink>
        );
      })}
    </BreadcrumbsContainer>
  );
}
