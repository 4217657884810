import { ColorSwitch, Moon, Sun } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';

import { AppTheme } from 'utils/appTheme/constants';
import {
  setCurrentAppTheme,
  usePreviewAppTheme
} from 'utils/appTheme/internal';

const appThemeSvgs = {
  classic: <ColorSwitch size={20} />,
  light: <Sun size={20} />,
  dark: <Moon size={20} />
} satisfies Record<AppTheme, unknown>;

export function ThemeButton({ theme }: { theme: AppTheme }): JSX.Element {
  const [t] = useTranslation();
  const { activeTheme, clearPreviewTheme, setPreviewTheme } =
    usePreviewAppTheme();
  return (
    <button
      type="button"
      data-selected={theme === activeTheme}
      onClick={() => setCurrentAppTheme(theme)}
      onMouseEnter={() => setPreviewTheme(theme)}
      onMouseLeave={() => clearPreviewTheme()}
    >
      {appThemeSvgs[theme]}
      <span>{t(`settings:${theme}_mode`)}</span>
    </button>
  );
}
