import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';

import {
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import {
  TICKET_FORM_ID,
  TicketFieldId,
  ZENDESK_URL
} from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const submitFormAction = ({
  name,
  email,
  subject,
  country,
  supportNumber,
  shopName,
  companyName,
  uploads,
  history,
  dispatch
}: {
  name: string;
  email: string;
  subject: string;
  country: string;
  supportNumber: string;
  shopName: string;
  companyName: string;
  uploads: string[];
  history: RouteComponentProps['history'];
  dispatch: (action: HelpCenterReducerActions) => void;
}): void => {
  dispatch({
    type: HelpCenterAction.SetFormSubmitingState,
    payload: {
      isSubmittingForm: true
    }
  });
  axios
    .post(
      `${ZENDESK_URL}requests`,
      {
        request: {
          ticket_form_id: TICKET_FORM_ID,
          requester: { name, email },
          subject,
          fields: {
            [TicketFieldId.UserName]: name,
            [TicketFieldId.Country]: country,
            [TicketFieldId.SupportNumber]: supportNumber,
            [TicketFieldId.ShopName]: shopName,
            [TicketFieldId.CompanyName]: companyName
          },
          tags: ['help_center'],
          comment: {
            body: `${subject}\n\n---------------\nSubmitted from: ${window.location.origin}${window.location.pathname}`,
            uploads
          }
        }
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    .then(() => {
      dispatch({
        type: HelpCenterAction.SubmitForm
      });
      history.replace(`${history.location.search}&messageSent=true`);
    })
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SubmitForm
        }
      });
      dispatch({
        type: HelpCenterAction.SetFormSubmitingState,
        payload: {
          isSubmittingForm: false
        }
      });
    });
};
