import { AppName } from './constants';

/**
 * The `defaultEnvironment` and `appPaths` variables are used as exports, normally this shouldn't be
 * an issue if the functions in this file are used within the SSPA structure. Outside the SSPA or with
 * an incorrect CONFIG setup we fall back to hardcoded production values.
 */

const defaultEnvironment =
  (typeof CONFIG !== 'undefined' ? CONFIG.environment : undefined) ||
  'production';

export function getWindowTarget(
  appName: AppName | `${AppName}`,
  environment: string = defaultEnvironment
): string {
  return `tablecheck_${environment}_${appName}`;
}

const appPaths = {
  manager: 'https://manager.app.tablecheck.com/',
  'legacy-settings': 'https://settings.app.tablecheck.com',
  insight: '/insight',
  settings: '/settings',
  home: '/'
};

export function getAppPath(
  appKey: 'manager' | 'legacy-settings' | 'insight' | 'settings' | 'home'
): string {
  return (typeof CONFIG !== 'undefined' ? CONFIG.appPaths : appPaths)[appKey];
}

export function getAppLink(appName: `${AppName}`): string {
  return appPaths[appName];
}

/**
 * For applications within the SSPA structure, use this on the window to ensure compatability.
 * for other apps
 * @param appName
 * @param subPath
 * @param environment
 */
export function openPortalApp(
  appName: `${AppName}`,
  subPath?: string,
  environment: string = defaultEnvironment
): void {
  window.open(
    `${getAppLink(appName)}${subPath ? `/${subPath}` : ''}`.replace(
      /\/+/gi,
      '/'
    ),
    getWindowTarget(appName, environment)
  );
}

declare global {
  interface Window {
    /**
     * Shared function to allow switching of apps using centralised logic
     * @param appName
     * @param subPath Path after the app name, should include `/` at the start
     */
    openPortalApp(appName: `${AppName}`, subPath?: string): void;
  }
}

window.openPortalApp = (appName, subPath) =>
  openPortalApp(appName, subPath, defaultEnvironment);
