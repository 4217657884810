import axios, { AxiosResponse } from 'axios';

import {
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskArticleResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const loadArticleAction = ({
  locale,
  articleId,
  dispatch
}: {
  dispatch: (action: HelpCenterReducerActions) => void;
  locale: string;
  articleId: number;
}): ((message: string) => void) => {
  const source = axios.CancelToken.source();
  dispatch({
    type: HelpCenterAction.SetArticleLoadingState,
    payload: {
      isLoadingArticle: true
    }
  });
  axios
    .get(`${ZENDESK_URL}help_center/${locale}/articles/${articleId}`, {
      cancelToken: source.token
    })
    .then(
      ({ data: { article } }: AxiosResponse<ZendeskArticleResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetArticle,
          payload: {
            article
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetArticle
        }
      });
      dispatch({
        type: HelpCenterAction.SetArticleLoadingState,
        payload: {
          isLoadingArticle: false
        }
      });
    });
  return (message: string) => {
    dispatch({
      type: HelpCenterAction.SetArticle,
      payload: {
        article: null
      }
    });
    source.cancel(message);
  };
};
