import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { Spinner } from '@tablecheck/tablekit-spinner';
import * as React from 'react';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import {
  FileElement,
  FileName,
  RemoveIcon
} from 'components/TopNav/HelpCenter/styled';

interface Props {
  file: File;
  removeFile: (file: File) => void;
}

export function Attachment({ file, removeFile }: Props): JSX.Element {
  const {
    uploadingFiles,
    actions: { uploadFile }
  } = React.useContext(HelpCenterContext);
  React.useEffect(() => {
    const formData = new FormData();
    formData.append('uploaded_data', file);
    uploadFile(file, formData);
  }, []);

  return (
    <FileElement>
      <FileName>{file.name}</FileName>
      {uploadingFiles.indexOf(file) !== -1 ? (
        <Spinner />
      ) : (
        <RemoveIcon
          aria-selected
          icon={faTimes}
          onClick={() => removeFile(file)}
        />
      )}
    </FileElement>
  );
}
