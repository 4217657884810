import { locales } from '@tablecheck/locales';
import DOMPurify from 'dompurify';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { MikanWrapper, getMikanText } from 'components/MikanWrapper';
import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { ArticleType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { EmptyList } from 'components/TopNav/HelpCenter/elements/EmptyList';
import { Loader } from 'components/TopNav/HelpCenter/elements/Loader';
import {
  ArticleContainer,
  ScrollableContent,
  SubTitle
} from 'components/TopNav/HelpCenter/styled';
import { escapeRegExp } from 'utils/index';

const placeholderSrc = (width = 16, height = 9) =>
  `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

interface Props {
  anchor: string | null;
  article: ArticleType;
  search: string | null;
}

export function ArticleContent({
  article,
  search,
  anchor
}: Props): JSX.Element {
  const articleContainer = React.useRef<HTMLDivElement>(null);
  const hasMounted = React.useRef(false);
  const [t, { language }] = useTranslation();
  const history = useHistory();
  const { body } = article;

  const sanitizedHTML = React.useMemo(() => {
    let content = body;
    if (search) {
      const regex = new RegExp(
        `>([^<]*)?(${escapeRegExp(search)})([^>]*)?<`,
        'ig'
      );
      content = content.replace(
        regex,
        '>$1<span class="highlighted">$2</span>$3<'
      );
    }
    content = DOMPurify.sanitize(content, { FORBID_ATTR: ['style', 'color'] });
    return language === locales.ja.code ? getMikanText(content) : content;
  }, [body, search, language]);

  React.useEffect(() => {
    const { current } = articleContainer;
    if (current && anchor) {
      const sectionElement = current.querySelector(`#${anchor}`);
      if (sectionElement) {
        sectionElement.scrollIntoView(
          hasMounted.current ? { behavior: 'smooth' } : undefined
        );
        hasMounted.current = true;
      }
    }
  }, [anchor, articleContainer, hasMounted]);

  React.useEffect(() => {
    const { current } = articleContainer;
    if (current) {
      const articleLinks = current.querySelectorAll('a[href*=articles]');
      const images = current.querySelectorAll('img[class*=image]');

      Array.prototype.forEach.call(images, (image) => {
        const imageSrc = image.src;
        const [width, height] = image.className.match(/(\d+)/g);
        image.setAttribute('src', placeholderSrc(width, height));

        image.addEventListener('load', () => {
          if (image.src !== imageSrc) {
            image.setAttribute('src', imageSrc);
          }
        });
      });

      Array.prototype.forEach.call(articleLinks, (link) => {
        const hrefChunks = link.href.split('/');
        const articleId = hrefChunks[hrefChunks.indexOf('articles') + 1];
        if (articleId) {
          link.addEventListener('click', (e: Event) => {
            e.preventDefault();
            history.replace(`?view=help&articleId=${articleId}`);
          });
        }
      });
    }
  }, [sanitizedHTML, articleContainer, history]);
  return (
    <ArticleContainer
      labelRelatedArticles={t('portal:help.help_center_related_articles')}
      ref={articleContainer}
      dangerouslySetInnerHTML={{
        __html: sanitizedHTML
      }}
    />
  );
}

export function Article(): JSX.Element {
  const {
    anchor,
    article,
    articleId,
    isLoadingArticle,
    search,
    actions: { loadArticle }
  } = React.useContext(HelpCenterContext);
  React.useEffect(() => {
    if (!articleId) return () => {};
    return loadArticle(articleId);
  }, [articleId, loadArticle]);
  return (
    <ScrollableContent>
      {isLoadingArticle && <Loader />}
      {!isLoadingArticle && article && (
        <>
          <SubTitle>
            <span>
              <MikanWrapper text={article.name} />
            </span>
          </SubTitle>
          <ArticleContent anchor={anchor} article={article} search={search} />
        </>
      )}
      {!isLoadingArticle && !article && <EmptyList />}
    </ScrollableContent>
  );
}
