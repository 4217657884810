import styled from '@emotion/styled';
import * as React from 'react';
import './types';

const StyledPortalButton = styled.button<{
  ['data-disabled']?: boolean;
}>`
  height: 100%;
  padding: 4px;
  font: var(--label);
  font-weight: 400;
  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    // specific height padding to fit in the top bar
    padding: 6px var(--spacing-l3);
    gap: var(--spacing-l3);
    border-radius: var(--border-radius-small);
    background-color: var(--portal-button-background);
    color: var(--portal-button-foreground);
  }
  &:not([data-disabled='true']) {
    cursor: pointer;
    &:hover > span {
      background-color: var(--portal-button-background-hover);
    }
  }
`;

/**
 * Use this styled component to keep the top navbar consistently styled.
 */
export const PortalButton = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> & {
    isDisabled?: boolean;
  }
>(
  ({ children, isDisabled, ...props }, ref): JSX.Element => (
    <StyledPortalButton ref={ref} data-disabled={isDisabled} {...props}>
      <span>{children}</span>
    </StyledPortalButton>
  )
);
