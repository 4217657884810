import { Menu } from '@carbon/icons-react';
import { Item } from '@tablecheck/tablekit-item';
import * as React from 'react';

import { DropdownMenu } from 'components/DropdownMenu';

import { NavItemButton } from '../styled';

import { MenuItem, registerHandler } from './exports';
import { OptionsWrapper } from './styled';

export function SettingsDialog(): JSX.Element | null {
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  React.useEffect(() => registerHandler(setMenuItems), []);

  if (!menuItems.length) return null;

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <NavItemButton>
          <Menu size={20} />
        </NavItemButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content>
        <OptionsWrapper>
          {menuItems.map(({ id, onClick, href, target, content }) => (
            <DropdownMenu.Item asChild key={id}>
              <Item
                as={href ? 'a' : undefined}
                href={href}
                target={target}
                onClick={onClick}
              >
                {content}
              </Item>
            </DropdownMenu.Item>
          ))}
        </OptionsWrapper>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
