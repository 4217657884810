import { Item } from '@tablecheck/tablekit-item';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { MikanWrapper } from 'components/MikanWrapper';
import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { Loader } from 'components/TopNav/HelpCenter/elements/Loader';
import { ScrollableContent } from 'components/TopNav/HelpCenter/styled';
import { AVAILABLE_CATEGORY_IDS } from 'utils/constants';

export function CategoriesList(): JSX.Element {
  const { categories, isLoadingCategories } =
    React.useContext(HelpCenterContext);
  const history = useHistory();
  return (
    <ScrollableContent>
      {isLoadingCategories ? (
        <Loader />
      ) : (
        <>
          {categories
            .filter(
              ({ id }) => AVAILABLE_CATEGORY_IDS.indexOf(id.toString()) !== -1
            )
            .map((category, index) => (
              <Item
                key={category.id}
                onClick={() =>
                  history.replace(`?view=help&categoryId=${category.id}`)
                }
              >
                {`${index + 1}. `}
                <MikanWrapper text={category.name} />
              </Item>
            ))}
        </>
      )}
    </ScrollableContent>
  );
}
