import { Item } from '@tablecheck/tablekit-item';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { MikanWrapper } from 'components/MikanWrapper';
import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { Loader } from 'components/TopNav/HelpCenter/elements/Loader';
import { ScrollableContent } from 'components/TopNav/HelpCenter/styled';

export function SectionsList(): JSX.Element {
  const { sections, isLoadingSections } = React.useContext(HelpCenterContext);
  const history = useHistory();

  return (
    <ScrollableContent>
      {isLoadingSections ? (
        <Loader />
      ) : (
        <>
          {sections.map((section, index) => (
            <Item
              key={section.id}
              onClick={() =>
                history.replace(
                  `?view=help&categoryId=${section.category_id}&sectionId=${section.id}`
                )
              }
            >
              {`${index + 1}. `}
              <MikanWrapper text={section.name} />
            </Item>
          ))}
        </>
      )}
    </ScrollableContent>
  );
}
