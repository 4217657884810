/**
 * Adapted from https://curiosity-driven.org/amd-loader-with-promises
 */
import scriptjs from 'scriptjs';
import { LifeCycles } from 'single-spa';

import { SspaAppProps } from 'apps/types';
import { AppName } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/ban-types
export type ImportName = `@tablesolution-next/${AppName}` | (string & {});

const resolves: Partial<Record<ImportName, LifeCycles<SspaAppProps>>> = {};

function createAbsoluteUrl(host: string, path: string) {
  if (path.match(/^http(s|):\/\//)) return path;
  return `${host.replace(/[/]+$/, '')}/${path.replace(/^[/]+/, '')}`;
}

declare global {
  type Imports = Partial<Record<ImportName, LifeCycles<SspaAppProps>>>;
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends Imports {}
}

export function urlAmdImport(
  host: string,
  paths: Record<string, string>,
  importName: ImportName
): Promise<LifeCycles<SspaAppProps>> {
  const previouslyLoadedImport = resolves[importName];
  if (previouslyLoadedImport) {
    return Promise.resolve(previouslyLoadedImport);
  }

  return new Promise((resolve, reject) => {
    const bundleName = `${importName}-webpack-runtime`;
    // load the runtime and vendors bundles required to run the main.js
    const loadUrls = [paths['client.js']]
      .filter((url) => !!url)
      .map((path) => createAbsoluteUrl(host, path));

    scriptjs(loadUrls, bundleName);
    scriptjs.ready(bundleName, () => {
      if (window[importName]) {
        // @tablecheck/scripts >= 1.13
        resolves[importName] = window[importName];
        delete window[importName];
      }
      const resolvedImport = resolves[importName];
      if (!resolvedImport)
        reject(new Error(`Failed to load import ${importName}`));
      else resolve(resolvedImport);
    });
  });
}
