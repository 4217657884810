import {
  alertClassicTheme,
  alertDarkTheme,
  alertThemeNamespace
} from '@tablecheck/tablekit-alert';
import {
  buttonClassicTheme,
  buttonDarkTheme,
  buttonThemeNamespace
} from '@tablecheck/tablekit-button';
import {
  inputClassicTheme,
  inputDarkTheme,
  inputThemeNamespace
} from '@tablecheck/tablekit-input';
import {
  itemClassicTheme,
  itemDarkTheme,
  itemThemeNamespace
} from '@tablecheck/tablekit-item';
import {
  panelClassicTheme,
  panelDarkTheme,
  panelThemeNamespace
} from '@tablecheck/tablekit-panel';
import {
  spinnerClassicTheme,
  spinnerDarkTheme,
  spinnerThemeNamespace
} from '@tablecheck/tablekit-spinner';
import {
  CLASSIC_COLORS,
  COLORS,
  DARK_COLORS
} from '@tablecheck/tablekit-theme';
import {
  topnavClassicTheme,
  topnavDarkTheme,
  topnavThemeNamespace
} from '@tablecheck/tablekit-topnav';

import { AppTheme, PortalSpaceTheme, portalThemeNamespace } from 'App/types';

export const portalClassicTheme: PortalSpaceTheme = {
  colors: {
    bgColor: '#f4f5f7',
    textColor: CLASSIC_COLORS.text,
    textSubtle: CLASSIC_COLORS.textSubtle,
    cardBg: 'white',
    navbarForeground: DARK_COLORS.text,
    navbarForegroundHovered: DARK_COLORS.text,
    navbarForegroundSelected: DARK_COLORS.text,
    navbarBackground: DARK_COLORS.surface,
    navbarBackgroundHovered: DARK_COLORS.hoverNeutralBackground,
    navbarBackgroundSelected: DARK_COLORS.selectedBackground,
    navbarSelectedBorder: DARK_COLORS.primary
  }
};

export const portalLightTheme: PortalSpaceTheme = {
  colors: {
    bgColor: '#f4f5f7',
    textColor: CLASSIC_COLORS.text,
    textSubtle: CLASSIC_COLORS.textSubtle,
    cardBg: 'white',
    navbarForeground: CLASSIC_COLORS.text,
    navbarForegroundHovered: CLASSIC_COLORS.primary2Hover,
    navbarForegroundSelected: CLASSIC_COLORS.text,
    navbarBackground: CLASSIC_COLORS.surface,
    navbarBackgroundHovered: CLASSIC_COLORS.hoverNeutralBackground,
    navbarBackgroundSelected: CLASSIC_COLORS.selectedBackground,
    navbarSelectedBorder: CLASSIC_COLORS.primary
  }
};

export const portalDarkTheme: PortalSpaceTheme = {
  colors: {
    bgColor: COLORS.GRAY.L9,
    textColor: COLORS.WHITE_TRANSLUCENT.L8,
    textSubtle: COLORS.WHITE_TRANSLUCENT.L6,
    cardBg: COLORS.GRAY.L8,
    navbarForeground: DARK_COLORS.text,
    navbarForegroundHovered: DARK_COLORS.primary2Hover,
    navbarForegroundSelected: DARK_COLORS.text,
    navbarBackground: DARK_COLORS.surface,
    navbarBackgroundHovered: DARK_COLORS.hoverNeutralBackground,
    navbarBackgroundSelected: DARK_COLORS.selectedBackground,
    navbarSelectedBorder: DARK_COLORS.primary
  }
};

export const classicTheme: AppTheme = {
  isDark: false,
  colors: {
    ...CLASSIC_COLORS,
    highlightedTextBgColor: COLORS.YELLOW.L4,
    appSwitcherOpen: COLORS.PURPLE.L3,
    appSwitcherClosed: 'white'
  },
  [portalThemeNamespace]: portalClassicTheme,
  [alertThemeNamespace]: alertClassicTheme,
  [buttonThemeNamespace]: buttonClassicTheme,
  [inputThemeNamespace]: inputClassicTheme,
  [itemThemeNamespace]: itemClassicTheme,
  [panelThemeNamespace]: panelClassicTheme,
  [spinnerThemeNamespace]: spinnerClassicTheme,
  [topnavThemeNamespace]: topnavDarkTheme
};

export const lightTheme: AppTheme = {
  isDark: false,
  colors: {
    ...CLASSIC_COLORS,
    highlightedTextBgColor: COLORS.YELLOW.L4,
    appSwitcherOpen: COLORS.PURPLE.L5,
    appSwitcherClosed: COLORS.PURPLE.L5
  },
  [portalThemeNamespace]: portalLightTheme,
  [alertThemeNamespace]: alertClassicTheme,
  [buttonThemeNamespace]: buttonClassicTheme,
  [inputThemeNamespace]: inputClassicTheme,
  [itemThemeNamespace]: itemClassicTheme,
  [panelThemeNamespace]: panelClassicTheme,
  [spinnerThemeNamespace]: spinnerClassicTheme,
  [topnavThemeNamespace]: topnavClassicTheme
};

export const darkTheme: AppTheme = {
  isDark: true,
  colors: {
    ...DARK_COLORS,
    highlightedTextBgColor: COLORS.YELLOW.L7,
    appSwitcherOpen: COLORS.PURPLE.L3,
    appSwitcherClosed: 'white'
  },
  [portalThemeNamespace]: portalDarkTheme,
  [alertThemeNamespace]: alertDarkTheme,
  [buttonThemeNamespace]: buttonDarkTheme,
  [inputThemeNamespace]: inputDarkTheme,
  [itemThemeNamespace]: itemDarkTheme,
  [panelThemeNamespace]: panelDarkTheme,
  [spinnerThemeNamespace]: spinnerDarkTheme,
  [topnavThemeNamespace]: topnavDarkTheme
};
