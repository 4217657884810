import { FranchiseType } from 'types/Franchise';
import {
  ApiMerchantUser,
  MerchantUser,
  MerchantUserRole,
  SUPER_ROLES_ADMIN
} from 'types/MerchantUser';
import { AccessibleShopType } from 'types/Shop';

export interface CurrentUserResponse {
  current_user: MerchantUser;
  accessible_shops: AccessibleShopType[];
  accessible_shops_by_id: Record<string, AccessibleShopType>;
  franchises: FranchiseType[];
  merchant_roles: MerchantUserRole[];
}

export interface ApiCurrentUserResponse {
  current_user: ApiMerchantUser;
  accessible_shops: AccessibleShopType[];
  franchises: FranchiseType[];
  merchant_roles: MerchantUserRole[];
}

export function getInitials(name = ''): string {
  const initialsMatch = name.match(/\b\w/g) || [];
  const firstInitial = initialsMatch.shift() || '';
  const lastInitial = initialsMatch.pop() || '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
}

export function parseCurrentUserResponse(
  data: ApiCurrentUserResponse
): CurrentUserResponse {
  const { current_user: merchantUserData, accessible_shops: shops } = data;
  return {
    ...data,
    current_user: {
      ...merchantUserData,
      is_admin: SUPER_ROLES_ADMIN.includes(
        merchantUserData.super_role as never
      ),
      is_root_admin: merchantUserData.super_role === 'root_admin',
      initials: getInitials(merchantUserData.name),
      merchant_role: data.merchant_roles.find(
        (r) => r.id === merchantUserData.merchant_role_id
      )
    },
    accessible_shops_by_id: shops.reduce((r, shop) => {
      r[shop.id] = shop;
      return r;
    }, {} as CurrentUserResponse['accessible_shops_by_id'])
  };
}
