import { ordered } from '@tablecheck/locales';
import { getAxios } from '@tablecheck/react-sso-authenticator';
import { AxiosResponse } from 'axios';
import { i18n as I18next } from 'i18next';

import { setCurrentAppTheme } from 'utils/appTheme/internal';
import {
  ApiCurrentUserResponse,
  CurrentUserResponse,
  parseCurrentUserResponse
} from 'utils/currentUser';

export async function loadUser(
  i18n: I18next,
  apiPath = `${CONFIG.apiPath}current_user`
): Promise<CurrentUserResponse> {
  const { data } = await getAxios().get<
    unknown,
    AxiosResponse<ApiCurrentUserResponse, unknown>
  >(apiPath);

  const { current_user: merchantUserData } = data;
  const { locale: defaultLocale } = merchantUserData;

  setCurrentAppTheme(merchantUserData.app_theme);

  if (
    ordered.some(({ code }) => code === defaultLocale) &&
    defaultLocale !== i18n.language
  ) {
    i18n.changeLanguage(defaultLocale);
  }

  return parseCurrentUserResponse(data);
}
