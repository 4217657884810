import {
  BrowserClient,
  defaultIntegrations,
  defaultStackParser,
  Hub,
  makeFetchTransport
} from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { CaptureContext } from '@sentry/types';

export enum AppEnvironment {
  Staging = 'staging',
  Pilot = 'pilot',
  Production = 'production'
}

let sentryHub: Hub | undefined;

export enum SentryTag {
  HelpCenter = 'help_center'
}

const getTraceRates = (): {
  tracesSampleRate: number;
  sampleRate: number;
} => {
  if (CONFIG.environment === AppEnvironment.Production) {
    return {
      tracesSampleRate: 0.6,
      sampleRate: 1.0
    };
  }

  if (CONFIG.isDevelopment) {
    return {
      sampleRate: 0.0,
      tracesSampleRate: 0.0
    };
  }

  return {
    sampleRate: 1.0,
    tracesSampleRate: 1.0
  };
};

export const initSentry = (): void => {
  const client = new BrowserClient({
    dsn: CONFIG.sentryDsn,
    integrations: [...defaultIntegrations, new Integrations.BrowserTracing()],
    autoSessionTracking: true,
    sendClientReports: true,
    environment: CONFIG.environment,
    release: `portal-frontend@${CONFIG.appVersion}`,
    stackParser: defaultStackParser,
    transport: makeFetchTransport,
    ...getTraceRates()
  });
  sentryHub = new Hub(client);
  sentryHub.startSession({ ignoreDuration: true });
  sentryHub.captureSession();
};

export const reportSentryError = (
  exception: Parameters<Hub['captureException']>[0],
  captureContext?: CaptureContext
): string => {
  if (!sentryHub) {
    throw new Error('Trying to report error before sentry initialized!');
  }

  return sentryHub.captureException(exception, { captureContext });
};

export type ReportSentryErrorType = typeof reportSentryError;

export const configureSentryScope: Hub['configureScope'] = (callback) => {
  if (!sentryHub) {
    throw new Error('Trying to report error before sentry initialized!');
  }

  sentryHub.configureScope(callback);
};
