import { Settings, Diagram, DataTable } from '@carbon/icons-react';
import { Item } from '@tablecheck/tablekit-item';
import { Size } from '@tablecheck/tablekit-theme';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { DropdownMenu } from 'components/DropdownMenu';
import { getAppPath, getWindowTarget } from 'utils/appLink';
import { AppName } from 'utils/constants';
import { useIsCurrentApp } from 'utils/useIsCurrentApp';

import { AppType } from '../types';

import { ItemsWrapper, Logo, LogoNavItem, SubTitle, Title } from './styled';

const AppItem = React.forwardRef<HTMLAnchorElement, { app: AppType }>(
  ({ app }, ref): JSX.Element => {
    const [t] = useTranslation();
    const isSelected = useIsCurrentApp(app.key);

    return (
      <Item
        ref={ref}
        elemBefore={app.icon}
        isSelected={isSelected}
        size={Size.Large}
        key={app.key}
        href={isSelected ? undefined : app.href}
        target={getWindowTarget(app.key)}
      >
        <Title>{t(app.name)}</Title>
        <SubTitle>{t(app.subtitle)}</SubTitle>
      </Item>
    );
  }
);

const apps: AppType[] = [
  {
    name: `portal:navigation.apps.${AppName.Manager}`,
    subtitle: `portal:dashboards.${AppName.Manager}.subtitle`,
    href: getAppPath('manager'),
    key: AppName.Manager,
    icon: <DataTable size={32} />
  },
  {
    name: `portal:navigation.apps.${AppName.Insight}`,
    subtitle: `portal:dashboards.${AppName.Insight}.subtitle`,
    href: getAppPath('insight'),
    key: AppName.Insight,
    icon: <Diagram size={32} />
  },
  {
    name: `portal:navigation.apps.${AppName.Settings}`,
    subtitle: `portal:dashboards.${AppName.Settings}.subtitle`,
    href: getAppPath('legacy-settings'),
    key: AppName.Settings,
    icon: <Settings size={32} />
  }
];

export function AppSwitcher(): JSX.Element {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <LogoNavItem width="fit-content">
          <Logo size="26px" />
        </LogoNavItem>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="start">
        <ItemsWrapper>
          {apps.map((app) => (
            <DropdownMenu.Item key={app.key} asChild>
              <AppItem app={app} />
            </DropdownMenu.Item>
          ))}
        </ItemsWrapper>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}
