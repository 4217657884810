import axios, { AxiosResponse } from 'axios';

import {
  AppCategoryIdType,
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskSearchArticlesResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { AVAILABLE_CATEGORY_IDS } from 'utils/constants';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const searchArticlesAction = ({
  search,
  categoryId,
  locale,
  dispatch
}: {
  categoryId: AppCategoryIdType;
  dispatch: (action: HelpCenterReducerActions) => void;
  locale: string;
  search: string;
}): ((message: string) => void) => {
  const source = axios.CancelToken.source();
  dispatch({
    type: HelpCenterAction.SetArticlesLoadingState,
    payload: {
      isLoadingArticles: true
    }
  });
  axios
    .get(
      `${ZENDESK_URL}help_center/articles/search?locale=${locale}&query=${search}${
        categoryId
          ? `&category=${categoryId}`
          : `&category=${AVAILABLE_CATEGORY_IDS.join(',')}`
      }&per_page=5&include=sections,categories
      `,
      {
        cancelToken: source.token
      }
    )
    .then(
      ({
        data: { results, sections }
      }: AxiosResponse<ZendeskSearchArticlesResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetSearchResults,
          payload: {
            articles: results,
            sections
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetSearchResults
        }
      });
      dispatch({
        type: HelpCenterAction.SetArticlesLoadingState,
        payload: {
          isLoadingArticles: false
        }
      });
    });
  return (message: string) => {
    dispatch({
      type: HelpCenterAction.SetArticlesList,
      payload: {
        articles: []
      }
    });
    source.cancel(message);
  };
};
