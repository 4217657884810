import isEmpty from 'lodash/isEmpty';
import * as React from 'react';

import { ApiImageSize } from 'types/MerchantUser';

export const useImagePreview = ({
  imageFile,
  imageUrls,
  size = 'original'
}: {
  imageFile?: File;
  imageUrls?: Record<ApiImageSize, string>;
  size?: ApiImageSize;
}): string =>
  React.useMemo(() => {
    if (imageFile) {
      try {
        return URL.createObjectURL(imageFile);
      } catch (e) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (imageFile as any).path || imageFile;
      }
    }

    if (!isEmpty(imageUrls)) {
      return imageUrls[size];
    }

    return undefined;
  }, [imageFile, imageUrls, size]);
