import { locales } from '@tablecheck/locales';
import Mikan from 'mikanjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

const hasHtmlTag = (input: string): boolean => {
  const htmlTagRegExp = /<[^>]*>/gi;
  return htmlTagRegExp.test(input);
};

const replaceHtmlEntites = (() => {
  const translateRe = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    nbsp: String.fromCharCode(160),
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>'
  };

  return (text: string) =>
    text.replace(
      translateRe,
      (substring: string, subMatch: keyof typeof translate) =>
        translate[subMatch]
    );
})();

const safeWrapMikan = (input: string): string => {
  try {
    return Mikan(input, { style: 'white-space: pre' });
  } catch (e) {
    return input;
  }
};

export const getMikanText = (input: string): string => {
  if (hasHtmlTag(input)) {
    const regex = />([^>]*)?</gi;
    return replaceHtmlEntites(input).replace(regex, ($0, $1) =>
      $0.replace($1, safeWrapMikan($1))
    );
  }
  return safeWrapMikan(input);
};

export const MikanWrapper = ({
  text = '',
  ignoreLang = false
}: {
  ignoreLang?: boolean;
  text: string;
}): JSX.Element => {
  const [, { language }] = useTranslation();
  return React.useMemo(() => {
    if (text === '') {
      return text as unknown as JSX.Element;
    }
    const output =
      language === locales.ja.code || ignoreLang ? getMikanText(text) : text;
    if (hasHtmlTag(output)) {
      return <span dangerouslySetInnerHTML={{ __html: output }} />;
    }
    return output as unknown as JSX.Element;
  }, [text, language, ignoreLang]);
};
