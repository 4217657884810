import axios, { AxiosResponse } from 'axios';

import {
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskSearchArticlesResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const uploadFileAction = ({
  file,
  formData,
  dispatch
}: {
  dispatch: (action: HelpCenterReducerActions) => void;
  file: File;
  formData: FormData;
}): void => {
  dispatch({
    type: HelpCenterAction.SetUploadingFiles,
    payload: {
      file
    }
  });
  axios
    .post(`${ZENDESK_URL}uploads?filename=${file.name}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(
      ({
        data: {
          upload: { token }
        }
      }: AxiosResponse<ZendeskSearchArticlesResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetFiles,
          payload: {
            fileToken: token
          }
        });
        dispatch({
          type: HelpCenterAction.SetUploadingFiles,
          payload: {
            file
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetFiles
        }
      });
      dispatch({
        type: HelpCenterAction.SetUploadingFiles,
        payload: {
          file
        }
      });
    });
};
