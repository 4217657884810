import * as React from 'react';

import { AppNoticeImageAndTextSectionAPI } from 'components/AppNoticesModal/types';

import { Image, ImagePlaceholder, Message, TextWrapper, Title } from './styles';

export function ImageAndText({
  element,
  messagePlaceholder = '',
  imagePlaceholder,
  imagePreview
}: {
  element: AppNoticeImageAndTextSectionAPI;
  messagePlaceholder?: string;
  imagePlaceholder?: string;
  imagePreview?: string;
}): JSX.Element {
  const {
    title_text: title,
    content_text: message,
    image_layout: imageLayout
  } = element;

  return (
    <TextWrapper
      data-image-position={imageLayout}
      data-variant={!title ? 'untitled' : undefined}
    >
      {title ? <Title>{title}</Title> : null}
      {imagePreview ? (
        <Image src={imagePreview} alt={title} />
      ) : (
        <ImagePlaceholder>
          <span>{imagePlaceholder}</span>
        </ImagePlaceholder>
      )}
      <Message data-text-placeholder={!message}>
        <div
          dangerouslySetInnerHTML={{
            __html: message || messagePlaceholder
          }}
        />
      </Message>
    </TextWrapper>
  );
}
