import axios, { AxiosResponse } from 'axios';

import {
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskCategoryResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const loadCategoriesAction = ({
  locale,
  dispatch
}: {
  dispatch: (action: HelpCenterReducerActions) => void;
  locale: string;
}): ((message: string) => void) => {
  const source = axios.CancelToken.source();
  dispatch({
    type: HelpCenterAction.SetCategoriesListLoadingState,
    payload: {
      isLoadingCategories: true
    }
  });
  axios
    .get(`${ZENDESK_URL}help_center/${locale}/categories/`, {
      cancelToken: source.token
    })
    .then(
      ({
        data: { categories }
      }: AxiosResponse<ZendeskCategoryResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetCategoriesList,
          payload: {
            categories
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetCategoriesList
        }
      });
      dispatch({
        type: HelpCenterAction.SetCategoriesListLoadingState,
        payload: {
          isLoadingCategories: false
        }
      });
    });
  return (message: string) => {
    dispatch({
      type: HelpCenterAction.SetCategoriesList,
      payload: {
        categories: []
      }
    });
    source.cancel(message);
  };
};
