import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import { Icon } from '@tablecheck/tablekit-icon';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import { IconWrapper } from 'components/TopNav/HelpCenter/styled';

export function GoBack(): JSX.Element | null {
  const {
    helpScreen,
    categoryId,
    sectionId,
    articleId,
    search,
    isGlobalSearch
  } = React.useContext(HelpCenterContext);
  const history = useHistory();

  const goToPreviousSection = React.useCallback(() => {
    if (helpScreen === HelpCenterScreen.SectionsList) {
      return history.replace('?view=help');
    }
    if (helpScreen === HelpCenterScreen.Section && categoryId) {
      return history.replace(`?view=help&categoryId=${categoryId}`);
    }
    if (helpScreen === HelpCenterScreen.Article && categoryId) {
      if (search) {
        return history.replace(
          `?view=help${
            isGlobalSearch ? '' : `&categoryId=${categoryId}`
          }&search=${search}`
        );
      }
      return history.replace(
        `?view=help&categoryId=${categoryId}${
          sectionId ? `&sectionId=${sectionId}` : ''
        }`
      );
    }
    if (helpScreen === HelpCenterScreen.Search) {
      return history.replace(
        `?view=help${categoryId ? `&categoryId=${categoryId}` : ''}`
      );
    }
    if (helpScreen === HelpCenterScreen.ContactUsForm) {
      return history.replace(
        `?view=help${categoryId ? `&categoryId=${categoryId}` : ''}${
          sectionId ? `&sectionId=${sectionId}` : ''
        }${articleId ? `&articleId=${articleId}` : ''}${
          search ? `&search=${search}` : ''
        }`
      );
    }
    if (helpScreen === HelpCenterScreen.MessageSent) {
      return history.replace(
        `?view=help${categoryId ? `&categoryId=${categoryId}` : ''}${
          sectionId ? `&sectionId=${sectionId}` : ''
        }${articleId ? `&articleId=${articleId}` : ''}${
          search ? `&search=${search}` : ''
        }&contactForm=true`
      );
    }
    return undefined;
  }, [
    helpScreen,
    categoryId,
    history,
    search,
    sectionId,
    isGlobalSearch,
    articleId
  ]);

  if (helpScreen === HelpCenterScreen.CategoriesList) return null;

  return (
    <IconWrapper onClick={goToPreviousSection}>
      <Icon icon={faArrowLeft} />
    </IconWrapper>
  );
}
