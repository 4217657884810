import styled from '@emotion/styled';
import { CheckboxRadioLabel, Checkbox } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { TFunction } from 'react-i18next';

export const AgreementContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-l5);
  gap: var(--spacing-l2);
  background-color: var(--error-surface);
  border: 1px solid var(--error);
  border-radius: var(--border-radius-small);
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  &[data-state='checked'] {
    background-color: var(--surface-raised);
    border-color: var(--border);
  }
`;

export function BillingAgreement({
  hasAgreementAccepted = false,
  toggleAgreement = () => {},
  t
}: {
  hasAgreementAccepted?: boolean;
  toggleAgreement?: () => void;
  t: TFunction;
}): JSX.Element {
  return (
    <AgreementContent
      data-state={hasAgreementAccepted ? 'checked' : 'unchecked'}
    >
      <CheckboxRadioLabel>
        <Checkbox
          defaultChecked={hasAgreementAccepted}
          onChange={toggleAgreement}
        />
        {t('portal:app_notice.agreement_text')}
      </CheckboxRadioLabel>
    </AgreementContent>
  );
}
