import moment, { Moment } from 'moment';
import { TFunction } from 'react-i18next';

import { RoutePath } from 'utils/constants';

import { getAppPath } from './appLink';

export function isInsightSettingsPage(pathname: string): boolean {
  return (
    pathname.indexOf(`${getAppPath('insight')}/${RoutePath.Settings}`) !== -1
  );
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const emailRegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,12}$/;

export function useFormatDate(
  t: TFunction
): (dateMoment: Moment | string) => string {
  const format = t('settings:date.formats.display', 'yyyy-mm-dd', {
    postProcess: []
  }).toUpperCase();
  return (dateMoment) =>
    (moment.isMoment(dateMoment) ? dateMoment : moment(dateMoment)).format(
      format
    );
}
