export enum HelpCenterScreen {
  Article = 'article',
  CategoriesList = 'categories_list',
  ContactUsForm = 'contact_us_form',
  MessageSent = 'message_sent',
  SectionsList = 'sections_list',
  Section = 'section',
  Search = 'top_suggestions'
}

export enum ZendeskCountryField {
  Australia = 'australia',
  Cambodia = 'cambodia',
  China = 'china',
  Egypt = 'egypt',
  France = 'france',
  Guam = 'guam',
  HongKong = 'hong_kong',
  Indonesia = 'indonesia',
  Japan = 'japan',
  Jordan = 'jordan',
  Laos = 'laos',
  Luxembourg = 'luxembourg',
  Macau = 'macau',
  Malaysia = 'malaysia',
  Maldives = 'maldives',
  NewZealand = 'new_zealand',
  Philippines = 'philippines',
  Qatar = 'qatar',
  Singapore = 'singapore',
  SouthKorea = 'south_korea',
  Taiwan = 'taiwan',
  Thailand = 'thailand',
  Tunisia = 'tunisia',
  UAE = 'uae',
  USA = 'usa',
  Vietnam = 'vietnam'
}

export enum TicketFieldId {
  UserName = '900003024006',
  Country = '900003187326',
  SupportNumber = '900002702163',
  ShopName = '900002702183',
  CompanyName = '900002715786'
}

export const TICKET_FORM_ID = '900000025286';

export const ZENDESK_HELP_CENTER_URL =
  'https://support-restaurants.tablecheck.com/hc/';

export const ZENDESK_URL = 'https://support-restaurants.tablecheck.com/api/v2/';
