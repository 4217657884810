import {
  ArticleType,
  SectionType,
  CategoryType
} from 'components/TopNav/HelpCenter/ZendeskTypes';
import { HelpCenterScreen } from 'components/TopNav/HelpCenter/keys';
import { MerchantUser } from 'types/MerchantUser';
import { CATEGORY_ID } from 'utils/constants';

export type CancelRequestType = (message?: string | null) => void;

export type AppCategoryIdType = (typeof CATEGORY_ID)[keyof typeof CATEGORY_ID];

export type HelpCenterScreenType = HelpCenterScreen;

export type HelpCenterState = {
  anchor: string | null;
  article: ArticleType | null;
  articleId: number | null;
  articles: ArticleType[];
  categories: CategoryType[];
  categoryId: AppCategoryIdType | null;
  isContactForm: boolean;
  files: string[];
  helpScreen: HelpCenterScreen;
  isGlobalSearch: boolean;
  isLoadingArticle: boolean;
  isLoadingArticles: boolean;
  isLoadingCategories: boolean;
  isLoadingSections: boolean;
  isSubmittingForm: boolean;
  isMessageSent: boolean;
  search: string | null;
  sectionId: number | null;
  sections: SectionType[];
  uploadingFiles: File[];
};

export type HelpCenterActionsType = {
  loadArticle: (articleId: number) => CancelRequestType;
  loadArticles: (sectionId: number) => CancelRequestType;
  loadCategories: () => CancelRequestType;
  loadSections: (categoryId: AppCategoryIdType) => CancelRequestType;
  resetFiles: () => void;
  searchArticles: (
    search: string,
    categoryId?: AppCategoryIdType | null
  ) => CancelRequestType;
  submitForm: (request: {
    email: string;
    name: string;
    country: string;
    supportNumber: string;
    companyName: string;
    shopName: string;
    subject: string;
    uploads: string[];
  }) => void;
  uploadFile: (file: File, formData: FormData) => void;
};

export type HelpCenterContextType = HelpCenterState & {
  actions: HelpCenterActionsType;
  user: MerchantUser;
};

export type SetHelpScreenPayload = {
  anchor: string | null;
  articleId: number | null;
  categoryId: AppCategoryIdType | null;
  contactForm: boolean;
  isGlobalSearch: boolean;
  isMessageSent: boolean;
  search: string | null;
  sectionId: number | null;
};

export enum HelpCenterAction {
  ResetFiles = 'help_center/RESET_FILES',
  SetArticle = 'help_center/SET_ARTICLE',
  SetArticlesList = 'help_center/SET_ARTICLES_LIST',
  SetArticleLoadingState = 'help_center/SET_ARTICLE_LOADING_STATE',
  SetArticlesLoadingState = 'help_center/SET_ARTICLES_LOADING_STATE',
  SetCategoriesList = 'help_center/SET_CATEGORIES_LIST',
  SetCategoriesListLoadingState = 'help_center/SET_CATEGORIES_LIST_LOADING_STATE',
  SetFiles = 'help_center/SET_FILES',
  SetUploadingFiles = 'help_center/SET_UPLOADING_FILES',
  SetFormSubmitingState = 'help_center/SET_FORM_SUBMITING_STATE',
  SetSearchResults = 'help_center/SET_SEARCH_RESULTS',
  SetSectionsListLoadingState = 'help_center/SET_SECTIONS_LIST_LOADING_STATE',
  SetScreen = 'help_center/SHOW_SCREEN',
  SetSectionsList = 'help_center/SET_SECTIONS_LIST',
  SubmitForm = 'help_center/SUBMIT_FORM'
}

export type HelpSetSectionsListType = {
  payload: {
    sections: SectionType[];
  };
  type: HelpCenterAction.SetSectionsList;
};

export type HelpSetArticlesListType = {
  payload: {
    articles: ArticleType[];
  };
  type: HelpCenterAction.SetArticlesList;
};

export type HelpSetSearchResultsListType = {
  payload: {
    articles: ArticleType[];
    sections?: SectionType[];
  };
  type: HelpCenterAction.SetSearchResults;
};

export type HelpSetCategoriesListType = {
  payload: {
    categories: CategoryType[];
  };
  type: HelpCenterAction.SetCategoriesList;
};

export type HelpSetScreenType = {
  payload: SetHelpScreenPayload;
  type: HelpCenterAction.SetScreen;
};

export type HelpSetArticleType = {
  payload: {
    article: ArticleType | null;
  };
  type: HelpCenterAction.SetArticle;
};

export type HelpSetLoadingArticleStateType = {
  payload: {
    isLoadingArticle: boolean;
  };
  type: HelpCenterAction.SetArticleLoadingState;
};

export type HelpSetLoadingArticlesStateType = {
  payload: {
    isLoadingArticles: boolean;
  };
  type: HelpCenterAction.SetArticlesLoadingState;
};

export type HelpSetLoadingCategoriesStateType = {
  payload: {
    isLoadingCategories: boolean;
  };
  type: HelpCenterAction.SetCategoriesListLoadingState;
};

export type HelpSetLoadingSectionsStateType = {
  payload: {
    isLoadingSections: boolean;
  };
  type: HelpCenterAction.SetSectionsListLoadingState;
};

export type HelpSetFilesType = {
  payload: {
    fileToken: string;
  };
  type: HelpCenterAction.SetFiles;
};
export interface FileUploadPayload {
  file: File;
}
export type HelpSetUploadingFilesType = {
  payload: FileUploadPayload;
  type: HelpCenterAction.SetUploadingFiles;
};

export type HelpResetFilesType = {
  type: HelpCenterAction.ResetFiles;
};

export type HelpSetFormSubmitingFormStateType = {
  payload: {
    isSubmittingForm: boolean;
  };
  type: HelpCenterAction.SetFormSubmitingState;
};

export type HelpSubmitFormType = {
  type: HelpCenterAction.SubmitForm;
};

export type HelpCenterReducerActions =
  | HelpSetArticlesListType
  | HelpSetSectionsListType
  | HelpSetCategoriesListType
  | HelpSetScreenType
  | HelpSetArticleType
  | HelpSetLoadingArticleStateType
  | HelpSetLoadingArticlesStateType
  | HelpSetLoadingCategoriesStateType
  | HelpSetLoadingSectionsStateType
  | HelpSetFilesType
  | HelpSetFormSubmitingFormStateType
  | HelpSubmitFormType
  | HelpResetFilesType
  | HelpSetSearchResultsListType
  | HelpSetUploadingFilesType;
