import { useTranslation } from 'react-i18next';

import {
  EmptyText,
  ScrollableContent
} from 'components/TopNav/HelpCenter/styled';

export function EmptyList(): JSX.Element {
  const [t] = useTranslation();
  return (
    <ScrollableContent>
      <EmptyText>{t('portal:help.no_options_message')}</EmptyText>
    </ScrollableContent>
  );
}
