import styled from '@emotion/styled';

export const TextWrapper = styled.div<{
  'data-image-position'?: 'left' | 'right';
  'data-variant'?: 'default' | 'untitled';
}>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'title title' 'image message';
  row-gap: var(--spacing-l2);
  column-gap: var(--spacing-l4);
  padding: var(--spacing-l1);

  &[data-variant='untitled'] {
    grid-template-areas: 'image message';
  }

  &[data-image-position='right'] {
    grid-template-areas: 'title title' 'message image';

    &[data-variant='untitled'] {
      grid-template-areas: 'message image';
    }
  }
`;

export const Title = styled.div<{
  'data-text-placeholder'?: boolean;
}>`
  grid-area: title;
  font: var(--h4);
  text-align: center;

  &[data-text-placeholder='true'] {
    color: var(--text-placeholder);
  }
`;

export const Message = styled.div<{
  'data-text-placeholder'?: boolean;
}>`
  grid-area: message;
  font: var(--body-2);

  &[data-text-placeholder='true'] {
    text-align: center;
    color: var(--text-placeholder);
  }
`;

export const Image = styled.img`
  max-width: 100%;
  min-height: 100%;
  object-fit: contain;
`;

export const ImagePlaceholder = styled.span`
  text-align: center;
  font: var(--body-2);
  color: var(--text-placeholder);
`;
