import axios, { AxiosResponse } from 'axios';

import { ITEMS_PER_PAGE } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import {
  AppCategoryIdType,
  HelpCenterAction,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { ZendeskCategorySectionsResponseType } from 'components/TopNav/HelpCenter/ZendeskTypes';
import { ZENDESK_URL } from 'components/TopNav/HelpCenter/keys';
import { reportSentryError, SentryTag } from 'utils/sentry';

export const loadSectionsAction = ({
  categoryId,
  locale,
  dispatch
}: {
  categoryId: AppCategoryIdType;
  dispatch: (action: HelpCenterReducerActions) => void;
  locale: string;
}): ((message: string) => void) => {
  const source = axios.CancelToken.source();
  dispatch({
    type: HelpCenterAction.SetSectionsListLoadingState,
    payload: {
      isLoadingSections: true
    }
  });
  axios
    .get(
      `${ZENDESK_URL}help_center/${locale}/categories/${categoryId}/sections?per_page=${ITEMS_PER_PAGE}`,
      {
        cancelToken: source.token
      }
    )
    .then(
      ({
        data: { sections }
      }: AxiosResponse<ZendeskCategorySectionsResponseType>) => {
        dispatch({
          type: HelpCenterAction.SetSectionsList,
          payload: {
            sections
          }
        });
      }
    )
    .catch((error) => {
      reportSentryError(error, {
        tags: {
          [SentryTag.HelpCenter]: HelpCenterAction.SetSectionsList
        }
      });
      dispatch({
        type: HelpCenterAction.SetSectionsListLoadingState,
        payload: {
          isLoadingSections: false
        }
      });
    });
  return (message: string) => {
    dispatch({
      type: HelpCenterAction.SetSectionsList,
      payload: {
        sections: []
      }
    });
    source.cancel(message);
  };
};
