import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { getZendeskLocale } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import {
  AppCategoryIdType,
  HelpCenterAction,
  HelpCenterActionsType,
  HelpCenterReducerActions
} from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { loadArticleAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/loadArticle';
import { loadArticlesAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/loadArticles';
import { loadCategoriesAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/loadCategories';
import { loadSectionsAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/loadSections';
import { searchArticlesAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/searchArticles';
import { submitFormAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/submitForm';
import { uploadFileAction } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions/uploadFile';

export const useZendeskActions = (
  language: string,
  dispatch: (action: HelpCenterReducerActions) => void
): HelpCenterActionsType => {
  const history = useHistory();
  const locale = getZendeskLocale(language);

  const loadArticle = (articleId: number) =>
    loadArticleAction({ locale, articleId, dispatch });

  const loadArticles = (sectionId: number) =>
    loadArticlesAction({ locale, sectionId, dispatch });

  const loadCategories = () => loadCategoriesAction({ locale, dispatch });

  const loadSections = (categoryId: AppCategoryIdType) =>
    loadSectionsAction({ locale, categoryId, dispatch });

  const searchArticles = (search: string, categoryId: string) =>
    searchArticlesAction({ search, categoryId, locale, dispatch });

  const uploadFile = (file: File, formData: FormData) => {
    uploadFileAction({ file, formData, dispatch });
  };

  const resetFiles = () => {
    dispatch({
      type: HelpCenterAction.ResetFiles
    });
  };

  const submitForm = ({
    name,
    email,
    country,
    supportNumber,
    shopName,
    companyName,
    subject,
    uploads
  }: {
    name: string;
    email: string;
    country: string;
    supportNumber: string;
    shopName: string;
    companyName: string;
    subject: string;
    uploads: string[];
  }) => {
    submitFormAction({
      name,
      email,
      country,
      supportNumber,
      shopName,
      companyName,
      subject,
      uploads,
      history,
      dispatch
    });
  };

  return React.useMemo(
    () =>
      ({
        resetFiles,
        loadArticle,
        loadArticles,
        loadCategories,
        loadSections,
        searchArticles,
        uploadFile,
        submitForm
      } as HelpCenterActionsType),
    [dispatch, locale, history]
  );
};
