import { Item } from '@tablecheck/tablekit-item';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { MikanWrapper } from 'components/MikanWrapper';
import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { EmptyList } from 'components/TopNav/HelpCenter/elements/EmptyList';
import { Loader } from 'components/TopNav/HelpCenter/elements/Loader';
import {
  ScrollableContent,
  SubTitle
} from 'components/TopNav/HelpCenter/styled';

export function ArticlesList(): JSX.Element {
  const history = useHistory();
  const {
    articles,
    categoryId,
    sectionId,
    sections,
    isLoadingArticles,
    actions: { loadArticles }
  } = React.useContext(HelpCenterContext);
  React.useEffect(() => {
    if (!sectionId) return () => {};
    return loadArticles(sectionId);
  }, [loadArticles, sectionId]);
  const section = sections.find(({ id }) => id === sectionId);
  return (
    <ScrollableContent>
      {isLoadingArticles ? (
        <Loader />
      ) : (
        <>
          {section && <SubTitle>{section.name}</SubTitle>}
          {categoryId && articles.length ? (
            articles.map((article, index) => (
              <Item
                key={article.id}
                onClick={() =>
                  history.replace(
                    `?view=help&categoryId=${categoryId}&sectionId=${article.section_id}&articleId=${article.id}`
                  )
                }
              >
                {`${index + 1}. `}
                <MikanWrapper text={article.name} />
              </Item>
            ))
          ) : (
            <EmptyList />
          )}
        </>
      )}
    </ScrollableContent>
  );
}
