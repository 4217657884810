import { css, Global } from '@emotion/react';
import { ThemeProvider } from '@tablecheck/tablekit-react';
import { ThemeProvider as ThemeProviderTKv2 } from '@tablecheck/tablekit-theme';
import {
  commonTypographyStyles,
  desktopTypographyStyles
} from '@tablecheck/tablekit-typography';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { cssVariables } from 'cssVariableExports';
import { useAppTheme } from 'utils/appTheme/exports';

import { classicTheme, darkTheme, lightTheme } from './themes';

function useTheme() {
  const theme = useAppTheme();
  switch (theme) {
    case 'classic': {
      return classicTheme;
    }
    case 'dark': {
      return darkTheme;
    }
    case 'light': {
      return lightTheme;
    }
  }
}

export function InternalThemeProvider({
  children
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const appTheme = useAppTheme();
  const theme = useTheme();

  return (
    <ThemeProviderTKv2
      theme={theme}
      isDark={theme.isDark}
      renderHeadNodes={(nodes) => <Helmet>{nodes}</Helmet>}
      locale={language}
    >
      <ThemeProvider
        lang={language}
        theme={appTheme === 'dark' ? 'dark' : 'light'}
      >
        <Global
          styles={css`
            [data-theme='light'],
            :root {
              --app-preview-background: #eae6f4;
              --app-preview-surface: #ffffff;
            }

            @media (prefers-color-scheme: dark) {
              :root:not([data-theme='light']) {
                --app-preview-background: #370466;
                --app-preview-surface: #eae6f4;
              }
            }
            [data-theme='dark'] {
              --app-preview-background: #370466;
              --app-preview-surface: #eae6f4;
            }

            body {
              background-color: var(--surface-low);
              transition: all 0.3s ease-in-out;
            }
            ${cssVariables}
            ${commonTypographyStyles}
          ${desktopTypographyStyles}
          `}
        />
        {children}
      </ThemeProvider>
    </ThemeProviderTKv2>
  );
}
