import { LocaleCode } from '@tablecheck/locales';
import { getAxios } from '@tablecheck/react-sso-authenticator';

import type { ReportSentryErrorType } from 'utils/sentry';

import { LanguageChangeListener } from './types';

const localeStorageKey = 'tablecheck-merchant-locale';

const languageChangeListeners: LanguageChangeListener[] = [];

function persistLocaleToApi(
  reportSentryError: ReportSentryErrorType,
  apiPath: string,
  newLanguage: LocaleCode
) {
  if (window.localStorage.getItem(localeStorageKey) !== newLanguage) {
    window.localStorage.setItem(localeStorageKey, newLanguage);
  }
  getAxios()
    .put(apiPath, {
      locale: newLanguage
    })
    .catch((error) => {
      console.error(error);
      reportSentryError(error, {
        extra: {
          info: 'Update user app_theme failed'
        }
      });
    });
}

export function initLanguageChangeHandler(
  reportSentryError: ReportSentryErrorType,
  apiPath: string
): void {
  if (!window.onLanguageChanged) {
    window.onLanguageChanged = (handler) => {
      languageChangeListeners.push(handler);
      return () => {
        const index = languageChangeListeners.indexOf(handler);
        if (index === -1) return;
        languageChangeListeners.splice(index, 1);
      };
    };
    languageChangeListeners.push((newLocale) =>
      persistLocaleToApi(reportSentryError, apiPath, newLocale)
    );
  }
}

export function triggerLanguageChange(newLanguage: LocaleCode): void {
  languageChangeListeners.forEach((handler) =>
    handler(newLanguage as LocaleCode)
  );
}

window.addEventListener('storage', (event) => {
  const { key, newValue } = event;
  if (key !== localeStorageKey || !newValue) return;
  triggerLanguageChange(newValue as LocaleCode);
});
