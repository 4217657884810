import {
  Appearance as InputAppearance,
  Input,
  Label
} from '@tablecheck/tablekit-input';
import { Select } from '@tablecheck/tablekit-select';
import { Textarea as TextArea } from '@tablecheck/tablekit-textarea';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { getZendeskCountryOptions } from 'components/TopNav/HelpCenter/HelpCenterContext/utils';
import { FilesUploader } from 'components/TopNav/HelpCenter/elements/FilesUploader';
import { FormFooter } from 'components/TopNav/HelpCenter/elements/FormFooter';
import {
  FieldGroup,
  FormContainter,
  ScrollableContent
} from 'components/TopNav/HelpCenter/styled';
import { MENU_PORTAL_Z_INDEX } from 'components/TopNav/constants';
import { emailRegExp } from 'utils/index';

export function ContactUsForm(): JSX.Element {
  const {
    isSubmittingForm,
    user,
    files: filesTokens,
    uploadingFiles,
    actions: { submitForm }
  } = React.useContext(HelpCenterContext);
  const [t] = useTranslation();
  const [name, setName] = React.useState(user.name);
  const [email, setEmail] = React.useState(user.email || '');
  const [country, setCountry] = React.useState<string | null>(null);
  const [supportNumber, setSupportNumber] = React.useState('');
  const [companyName, setCompanyName] = React.useState('');
  const [shopName, setShopName] = React.useState('');
  const [subject, setSubject] = React.useState('');
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const countryOptions = getZendeskCountryOptions(t);

  const validateFormFields = () => {
    const formErrors: Record<string, string> = {};
    let isFormValid = true;

    if (!name) {
      isFormValid = false;
      formErrors.name = t('portal:help.errors.blank_field');
    }

    if (!email) {
      isFormValid = false;
      formErrors.email = t('portal:help.errors.blank_field');
    }

    if (!country) {
      isFormValid = false;
      formErrors.country = t('portal:help.errors.blank_field');
    }

    if (!companyName) {
      isFormValid = false;
      formErrors.companyName = t('portal:help.errors.blank_field');
    }

    if (!shopName) {
      isFormValid = false;
      formErrors.shopName = t('portal:help.errors.blank_field');
    }

    if (!subject) {
      isFormValid = false;
      formErrors.subject = t('portal:help.errors.blank_field');
    }

    if (email && !emailRegExp.test(email)) {
      isFormValid = false;
      formErrors.email = t('portal:help.errors.invalid_email');
    }

    setErrors(formErrors);
    return isFormValid;
  };

  const onSubmit = () => {
    if (validateFormFields() && country) {
      submitForm({
        name,
        email,
        country,
        supportNumber,
        companyName,
        shopName,
        subject,
        uploads: filesTokens
      });
    }
  };

  return (
    <FormContainter>
      <ScrollableContent>
        <FieldGroup>
          <Input
            type="text"
            label={t('portal:help.form_fields.name')}
            name="name"
            onChange={({ currentTarget: { value } }) => setName(value)}
            value={name}
            shouldFitContainer
            isRequired
            appearance={
              errors.name ? InputAppearance.Invalid : InputAppearance.Default
            }
            isMessageHidden={!errors.name}
            message={errors.name}
          />
        </FieldGroup>
        <FieldGroup>
          <Input
            type="text"
            label={t('portal:help.form_fields.email')}
            name="email"
            onChange={({ currentTarget: { value } }) => setEmail(value)}
            value={email}
            isRequired
            appearance={
              errors.email ? InputAppearance.Invalid : InputAppearance.Default
            }
            isMessageHidden={!errors.email}
            shouldFitContainer
            message={errors.email}
          />
        </FieldGroup>
        <FieldGroup>
          <Label isRequired label={t('portal:help.form_fields.country')} />
          <Select
            shouldFitContainer
            menuPlacement="auto"
            menuPortalTarget={document.body}
            onChange={(option) => {
              const { value = null } = option || {};
              setCountry(value);
            }}
            options={countryOptions}
            isInvalid={!!errors.country}
            placeholder={t('portal:help.country_placeholder')}
            value={
              country
                ? {
                    label: t(`portal:help.zendesk_countries.${country}`),
                    value: country
                  }
                : null
            }
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: MENU_PORTAL_Z_INDEX,
                pointerEvents: 'auto'
              })
            }}
          />
        </FieldGroup>
        <FieldGroup>
          <Input
            type="number"
            label={t('portal:help.form_fields.support_number')}
            name="supportNumber"
            onChange={({ currentTarget: { value } }) => setSupportNumber(value)}
            value={supportNumber}
            appearance={
              errors.supportNumber
                ? InputAppearance.Invalid
                : InputAppearance.Default
            }
            isMessageHidden={!errors.supportNumber}
            shouldFitContainer
            message={errors.supportNumber}
          />
        </FieldGroup>
        <FieldGroup>
          <Input
            type="text"
            label={t('portal:help.form_fields.company_name')}
            name="companyName"
            onChange={({ currentTarget: { value } }) => setCompanyName(value)}
            value={companyName}
            isRequired
            appearance={
              errors.companyName
                ? InputAppearance.Invalid
                : InputAppearance.Default
            }
            isMessageHidden={!errors.companyName}
            shouldFitContainer
            message={errors.companyName}
          />
        </FieldGroup>
        <FieldGroup>
          <TextArea
            type="text"
            label={t('portal:help.form_fields.shop_name')}
            name="shopName"
            onChange={({ currentTarget: { value } }) => setShopName(value)}
            value={shopName}
            minimumRows={3}
            isRequired
            appearance={
              errors.shopName
                ? InputAppearance.Invalid
                : InputAppearance.Default
            }
            isMessageHidden={!errors.shopName}
            shouldFitContainer
            message={errors.shopName}
          />
        </FieldGroup>
        <FieldGroup>
          <TextArea
            type="text"
            label={t('portal:help.form_fields.subject')}
            name="subject"
            onChange={({ currentTarget: { value } }) => setSubject(value)}
            value={subject}
            minimumRows={6}
            isRequired
            appearance={
              errors.subject ? InputAppearance.Invalid : InputAppearance.Default
            }
            isMessageHidden={!errors.subject}
            shouldFitContainer
            message={errors.subject}
          />
        </FieldGroup>
        <FieldGroup>
          <FilesUploader />
        </FieldGroup>
      </ScrollableContent>
      <FormFooter
        onSubmit={onSubmit}
        isSubmittingForm={isSubmittingForm}
        isDisabled={!!uploadingFiles.length}
      />
    </FormContainter>
  );
}
