import styled from '@emotion/styled';
import { Banner } from '@tablecheck/tablekit-banner';
import { Icon } from '@tablecheck/tablekit-icon';
import { Input } from '@tablecheck/tablekit-input';
import {
  Spacing,
  TYPOGRAPHY,
  FieldHeight,
  FontWeight,
  BORDER_RADIUS
} from '@tablecheck/tablekit-theme';
import { Typography } from '@tablecheck/tablekit-typography';

export const ZendeskPanelContent = styled.div`
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  padding: ${Spacing.L4} ${Spacing.L4} ${Spacing.L4} ${Spacing.L5};

  .highlighted {
    background-color: ${({ theme }) => theme.colors.highlightedTextBgColor};
  }
`;

export const ScrollableContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  overflow: auto;
  padding-right: ${Spacing.L4};
`;

export const EmptyText = styled.div`
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.textSubtle};
  ${Typography.Body2};
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  a {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 0.1em;
    color: ${({ theme }) => theme.colors.primary2};
    position: relative;
    text-decoration: none;
    transition: background-size 150ms ease-in-out;
  }

  a:focus {
    outline: none;
  }
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  margin-top: ${Spacing.L2};
  width: 100%;
`;

export const ArticleContainer = styled.div<{ labelRelatedArticles: string }>`
  h2 {
    ${Typography.Heading3};
    margin: ${Spacing.L2} 0;
  }

  h3 {
    ${Typography.Heading4};
    margin: ${Spacing.L1} 0;
  }

  .indexms {
    margin-top: ${Spacing.L6};
    padding: 0;
    position: relative;

    li {
      padding: 0;
    }

    a {
      align-items: center;
      background: none;
      color: ${({ theme }) => theme.colors.text};
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      min-height: ${FieldHeight.Regular};
      padding: 0 ${Spacing.L3};
      text-decoration: none;
      width: 100%;
    }

    a:hover {
      background-color: ${({ theme }) => theme.colors.hoverNeutralBackground};
    }

    &::after {
      bottom: 100%;
      color: ${({ theme }) => theme.colors.text};
      content: ${({ labelRelatedArticles }) => `'${labelRelatedArticles}'`};
      font-weight: ${FontWeight.Bold};
      left: 0;
      position: absolute;
      ${TYPOGRAPHY.L3}
    }
  }

  img {
    display: block;
    height: auto;
    margin: auto;
    max-width: 100%;
  }

  a {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 0 0.1em;
    color: ${({ theme }) => theme.colors.primary2};
    position: relative;
    text-decoration: none;
    transition: background-size 150ms ease-in-out;
  }

  a:hover,
  a:focus {
    background-size: 100% 0.1em;
  }

  a:focus {
    outline: none;
  }

  ul,
  p,
  li {
    padding: ${Spacing.L2} 0;
    ${Typography.Body2};
  }
`;

export const Title = styled.h1`
  ${Typography.Heading3};
  color: ${({ theme }) => theme.colors.text};
  flex: 1;
  margin: 0;
`;

export const SubTitle = styled.h2`
  ${TYPOGRAPHY.L4};
  font-weight: ${FontWeight.Bold};
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  display: flex;
  margin: ${Spacing.L3} 0;

  > span {
    flex: 1;
  }

  a {
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.colors.primary2};
  }

  a:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const BreadcrumbsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  padding: ${Spacing.L2} 0;
  width: 100%;
  ${Typography.Body2};

  a:visited {
    color: ${({ theme }) => theme.colors.primary2};
  }

  > * {
    white-space: nowrap;
  }

  > *:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > *:not(:last-child):after {
    content: '>';
    margin: 0 ${Spacing.L1};
  }
`;

export const HelpCenterSearchBox = styled(Input)`
  flex: 0;
  height: fit-content;
  margin: ${Spacing.L2} 0;
`;

export const BreadcrumbCurrent = styled.span`
  color: ${({ theme }) => theme.colors.textSubtle};
  ${Typography.Body2};
`;

export const FormContainter = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const FieldLabel = styled.h4`
  ${TYPOGRAPHY.L2};
  font-weight: ${FontWeight.Bold};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${Spacing.L2};
`;

export const FieldGroup = styled.div`
  box-sizing: border-box;
  margin: ${Spacing.L2} 0;
  width: 100%;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DropzoneArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${Spacing.L6};
  border-width: 2px;
  border-radius: ${BORDER_RADIUS}px;
  border-color: ${({ theme }) => theme.colors.border};
  border-style: dashed;
  background-color: ${({ theme }) => theme.colors.canvas};
  color: ${({ theme }) => theme.colors.text};
  outline: none;
  cursor: pointer;
  ${Typography.Body1};
`;

export const FileElement = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.canvas};
  color: ${({ theme }) => theme.colors.text};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${BORDER_RADIUS}px;
  margin-top: ${Spacing.L2};
  padding: ${Spacing.L3};
  ${Typography.Body1};
`;

export const FileName = styled.div`
  flex: 1;
`;

export const RemoveIcon = styled(Icon)`
  cursor: pointer;
`;

export const MessageSentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.text};
`;

export const MessageSentHeader = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin-bottom: ${Spacing.L3};
  ${Typography.Heading4}
`;

export const MessageSentDescription = styled.div`
  text-align: center;
  ${Typography.Body2};
`;

export const MessageSentIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.success};
  margin: 0 auto ${Spacing.L4};
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: ${Spacing.L1};
  }
`;

export const IconWrapper = styled.div`
  width: ${FieldHeight.Regular};
  height: ${FieldHeight.Regular};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBanner = styled(Banner)`
  margin-top: ${Spacing.L2};
`;
