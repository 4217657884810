import * as dashboardApp from '../scenes/LandingPage/dashboardApp';
import { AppName } from '../utils/constants';

import { SspaApp } from './base';

export const defaultApp = new SspaApp({
  name: AppName.Home,
  label: `portal:navigation.apps.${AppName.Home}`,
  path: '/',
  loadingFunction: () => Promise.resolve(dashboardApp),
  matchFunction: ({ pathname }) => /^(\/|)(\?|$)/gi.test(pathname)
});
