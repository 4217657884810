import * as React from 'react';

export interface MenuItem {
  id: string;
  onClick?: React.MouseEvent<HTMLButtonElement>;
  href?: string;
  target?: string;
  content: React.ReactNode;
}

let racingAssign: null | MenuItem[] = null;
const handlers: ((menuItems: MenuItem[]) => void)[] = [];

export function registerMenuItems(menuItems: MenuItem[]): VoidFunction {
  if (!handlers.length) {
    racingAssign = menuItems;
  } else {
    handlers.forEach((handler) => handler(menuItems));
  }
  return () => {
    racingAssign = null;
    handlers.forEach((handler) => handler([]));
  };
}

export function registerHandler(
  handler: (typeof handlers)[number]
): VoidFunction {
  if (!handlers.length && racingAssign) {
    handler(racingAssign);
    racingAssign = null;
  }
  handlers.push(handler);
  return () => {
    const currentIndex = handlers.indexOf(handler);
    handlers.splice(currentIndex, 1);
  };
}
