import queryString from 'query-string';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  getHelpCenterContext,
  HelpCenterContext,
  helpCenterReducer
} from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { HelpCenterAction } from 'components/TopNav/HelpCenter/HelpCenterContext/types';
import { useZendeskActions } from 'components/TopNav/HelpCenter/HelpCenterContext/zendeskActions';
import { MerchantUser } from 'types/MerchantUser';

export function HelpCenterContextProvider({
  children,
  user
}: {
  children: React.ReactNode;
  user: MerchantUser;
}): JSX.Element {
  const [, { language }] = useTranslation();
  const { search: queryParams } = useLocation();
  const {
    anchor = null,
    articleId = null,
    categoryId = null,
    sectionId = null,
    search = null,
    contactForm = false,
    messageSent = false,
    isGlobalSearch = false
  } = queryString.parse(queryParams) as { [key: string]: string };
  const [helpCenterState, dispatch] = React.useReducer(
    helpCenterReducer,
    getHelpCenterContext()
  );
  const zendeskActions = useZendeskActions(language, dispatch);

  React.useEffect(() => {
    dispatch({
      type: HelpCenterAction.SetScreen,
      payload: {
        sectionId: sectionId ? parseFloat(sectionId) : null,
        articleId: articleId ? parseFloat(articleId) : null,
        categoryId,
        isGlobalSearch: !!isGlobalSearch,
        search,
        anchor,
        contactForm: !!contactForm,
        isMessageSent: !!messageSent
      }
    });
  }, [
    isGlobalSearch,
    anchor,
    articleId,
    categoryId,
    messageSent,
    contactForm,
    sectionId,
    search,
    dispatch
  ]);
  const contextState = React.useMemo(
    () => ({
      ...helpCenterState,
      user,
      actions: {
        ...zendeskActions
      }
    }),
    [zendeskActions, helpCenterState, user]
  );
  return (
    <HelpCenterContext.Provider value={contextState}>
      {children}
    </HelpCenterContext.Provider>
  );
}
