import styled from '@emotion/styled';
import * as PopoverImport from '@radix-ui/react-popover';
import { DEPTH } from '@tablecheck/tablekit-theme';

export const Popover = {
  ...PopoverImport,
  Content: styled(PopoverImport.Content)`
    display: block;
    position: relative;
    background: ${({ theme }) => theme.colors.surface};
    ${DEPTH.OVERLAY}
  `
};
