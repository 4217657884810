import styled from '@emotion/styled';

import { Message } from './styles';

export const TextWrapper = styled.div<{ 'data-type'?: 'default' | 'framed' }>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  padding: var(--spacing-l1);
  &[data-type='framed'] {
    background-color: var(--grey-100);
    border: 1px solid var(--border);
    border-radius: var(--border-radius-small);
  }
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-l3);
  font: var(--label);
`;

export const Details = styled.details`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);

  &[open] > *:not(:last-child) {
    margin-bottom: var(--spacing-l2);
  }
`;

export const DetailsMessage = styled(Message)`
  background-color: var(--surface);
  padding: var(--spacing-l3);
  border: 1px solid var(--border);
  border-radius: var(--border-radius-small);
`;

export const Summary = styled.summary`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-l2);
  cursor: pointer;
  &::-webkit-details-marker,
  &::marker {
    display: none;
    content: '';
  }
`;
