import { BadgeVariant } from '@tablecheck/tablekit-react';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import * as React from 'react';
import { TFunction } from 'react-i18next';

import { CTAButton } from 'components/AppNoticesModal/components/CTAButton';
import { Image } from 'components/AppNoticesModal/components/Image';
import { ImageAndText } from 'components/AppNoticesModal/components/ImageAndText';
import { Text } from 'components/AppNoticesModal/components/Text';
import { Video } from 'components/AppNoticesModal/components/Video';
import {
  AppNoticeAPI,
  AppNoticeCategory,
  AppNoticeSectionAPI
} from 'components/AppNoticesModal/types';
import { AppName } from 'utils/constants';

export const appNoticesSeenLocaleStorageKey = 'app-notices-seen';

export const getAppNoticesSeenLocaleStorageKey = (
  userId: string,
  appName: AppName
): string => `${appNoticesSeenLocaleStorageKey}-${userId}-${appName}`;

export const getSeenAppNotices = (
  userId: string,
  appName: AppName
): string[] => {
  const localStorageKey = getAppNoticesSeenLocaleStorageKey(userId, appName);
  let seenAppNotices: string[] = [];
  const storageValue = localStorage.getItem(localStorageKey);

  if (typeof storageValue !== 'string') {
    return seenAppNotices;
  }

  try {
    seenAppNotices = JSON.parse(storageValue);
  } catch (error) {
    console.error(error);
  }

  return seenAppNotices;
};

export const setSeenAppNotices = (
  userId: string,
  appName: AppName,
  allAppNoticeIds: string[],
  shownAppNoticeIds: string[]
): string[] => {
  const localStorageKey = getAppNoticesSeenLocaleStorageKey(userId, appName);
  const seenAppNoticeIds = getSeenAppNotices(userId, appName);
  const existingAppNoticeIds = seenAppNoticeIds.filter((id) =>
    allAppNoticeIds.includes(id)
  );
  const newSeenAppNoticeIds = uniq([
    ...existingAppNoticeIds,
    ...shownAppNoticeIds
  ]);
  localStorage.setItem(localStorageKey, JSON.stringify(newSeenAppNoticeIds));
  return newSeenAppNoticeIds;
};

export const getFilteredNonBillingAppNoticesToShow = (
  userId: string,
  appName: AppName,
  appNotices: AppNoticeAPI[]
): AppNoticeAPI[] => {
  const seenAppNotices = getSeenAppNotices(userId, appName);
  return appNotices.filter(
    ({ id, category }) => !seenAppNotices.includes(id) && category !== 'billing'
  );
};

export const getNonBillingAppNotices = (
  appNotices: AppNoticeAPI[]
): AppNoticeAPI[] =>
  appNotices.filter(({ category }) => category !== 'billing');

export const getBillingAppNotices = (
  appNotices: AppNoticeAPI[]
): AppNoticeAPI[] =>
  appNotices.filter(({ category }) => category === 'billing');

export const getFilteredBillingAppNoticesToShow = (
  userId: string,
  appName: AppName,
  appNotices: AppNoticeAPI[]
): AppNoticeAPI[] => {
  const seenAppNotices = getSeenAppNotices(userId, appName);
  return appNotices.filter(
    ({ id, category }) => !seenAppNotices.includes(id) && category === 'billing'
  );
};

export function getBadgeVariant(category: AppNoticeCategory): BadgeVariant {
  switch (category) {
    case 'event':
      return 'info';
    case 'incident':
      return 'error';
    case 'release':
      return 'success';
    case 'maintenance':
      return 'warning';
    case 'campaign':
      return 'purple';
    case 'billing':
      return 'orange';
    default:
      return 'neutral';
  }
}

export const parseAppNotice = (appNotice: AppNoticeAPI): AppNoticeAPI => {
  const { app_notice_sections: appNoticeSections, ...restAppNotice } =
    appNotice;

  return {
    ...restAppNotice,
    app_notice_sections: appNoticeSections.sort(
      ({ position: a = 0 }, { position: b = 0 }) => a - b
    )
  };
};

export const getImagePreview = ({
  imageFile,
  imageUrls
}: {
  imageFile?: File;
  imageUrls?: Record<string, string>;
}): string | undefined => {
  if (imageUrls && !isEmpty(imageUrls)) {
    return imageUrls.original;
  }
  if (!imageFile) {
    return undefined;
  }
  try {
    return URL.createObjectURL(imageFile);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (imageFile as any).path || imageFile;
  }
};

export function renderElement(
  element: AppNoticeSectionAPI,
  t: TFunction
): React.ReactNode {
  switch (element.ui_type) {
    case 'image': {
      const imagePreview = getImagePreview({
        imageFile: element.image,
        imageUrls: element.image_urls
      });
      return <Image element={element} imagePreview={imagePreview} />;
    }
    case 'image_and_text': {
      const imagePreview = getImagePreview({
        imageFile: element.image,
        imageUrls: element.image_urls
      });
      return <ImageAndText element={element} imagePreview={imagePreview} />;
    }
    case 'text': {
      return <Text element={element} t={t} />;
    }
    case 'video': {
      return <Video element={element} />;
    }
    case 'cta_button': {
      return <CTAButton element={element} />;
    }
  }
}
