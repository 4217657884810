import { Badge } from '@tablecheck/tablekit-react';
import * as React from 'react';
import { TFunction } from 'react-i18next';

import { DateWrapper, Title } from 'components/AppNoticesModal/styles';
import { AppNoticeCategory } from 'components/AppNoticesModal/types';
import { getBadgeVariant } from 'components/AppNoticesModal/utils';
import { useFormatDate } from 'utils';

export function AppNoticeTitle({
  t,
  category,
  startDate
}: {
  category: AppNoticeCategory;
  startDate?: string;
  t: TFunction;
}): JSX.Element {
  const dateFormatter = useFormatDate(t);
  const isEventAppNotice = category === 'event';

  return (
    <Title>
      <Badge data-variant={getBadgeVariant(category)}>
        {t(`portal:app_notice.category.${category}`)}
      </Badge>
      {!isEventAppNotice && startDate ? (
        <DateWrapper>{dateFormatter(startDate)}</DateWrapper>
      ) : null}
    </Title>
  );
}
