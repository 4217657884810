import * as React from 'react';

import { HelpCenterContext } from 'components/TopNav/HelpCenter/HelpCenterContext/helpers';
import { escapeRegExp } from 'utils/index';

type PropsType = {
  children: (text: string) => JSX.Element | null;
  text: string;
};

export const SearchTextHighlighter = ({
  text,
  children
}: PropsType): JSX.Element | null => {
  const { search } = React.useContext(HelpCenterContext);

  if (search) {
    const regex = new RegExp(`([.])?(${escapeRegExp(search)})([.])?`, 'ig');
    const content = text.replace(
      regex,
      '$1<span class="highlighted">$2</span>$3'
    );
    return children(`<span>${content}</span>`);
  }
  return children(text);
};
