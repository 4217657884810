import { getAllEnumValues } from 'enum-for';
import { TFunction } from 'react-i18next';

import { ZendeskCountryField } from 'components/TopNav/HelpCenter/keys';

export const getZendeskCountryOptions = (
  t: TFunction
): { label: string; value: ZendeskCountryField }[] =>
  getAllEnumValues(ZendeskCountryField).map((country) => ({
    value: country,
    label: t(`portal:help.zendesk_countries.${country}`)
  }));
